@charset "UTF-8";
/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */
/* ========================================================================
     Component: layout
 ========================================================================== */
html {
  /* $replace rtl */
  direction: ltr;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  font-size: 16px; }

html, body, #app {
  height: 100%; }

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden; }
  .wrapper.ng-leave {
    display: none; }
  .wrapper .aside-container {
    position: absolute;
    width: 0px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 122;
    backface-visibility: hidden;
    background-color: #fff; }
    .wrapper .aside-container .aside-inner {
      height: 100%;
      width: 0px;
      overflow: hidden; }
    .wrapper .aside-container .nav-floating {
      left: inherit;
      margin-left: 0px;
      z-index: 1110;
      min-width: 190px;
      overflow: auto; }
  .wrapper .section-container {
    position: relative;
    height: 100%;
    margin-left: 0;
    z-index: 111;
    background-color: #fff;
    margin-bottom: 60px !important; }
  .wrapper .footer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    border-top: 1px solid #e4eaec;
    padding: 20px;
    z-index: 109;
    font-size: .875rem; }
    .wrapper .footer-container p {
      margin: 0; }

.modal-open .wrapper {
  z-index: 0; }

.content-wrapper {
  padding: 15px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px; }
  .content-wrapper .unwrap {
    margin: -15px; }
    @media (min-width: 768px) {
      .content-wrapper .unwrap {
        margin: -20px; } }
  .content-wrapper .content-heading {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.1;
    color: #929292;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: #fff;
    border-bottom: 1px solid #cfdbe2; }
    .content-wrapper .content-heading small {
      display: block;
      font-size: 12px;
      color: #909FA7; }
  .content-wrapper .container,
  .content-wrapper .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 768px) {
    .content-wrapper {
      padding: 20px; }
      .content-wrapper .content-heading {
        margin: -20px;
        margin-bottom: 20px;
        padding: 20px; }
        .content-wrapper .content-heading button,
        .content-wrapper .content-heading .btn {
          margin: 0; } }

@media (min-width: 768px) {
  body {
    min-height: 100%; }
  .wrapper .section-container,
  .wrapper .footer-container {
    margin-left: 0px; }
  .wrapper .section-container.has-sidebar-right {
    margin-right: 20px; }
    .wrapper .section-container.has-sidebar-right + .offsidebar {
      z-index: 1; } }

@media (max-width: 767.98px) {
  .wrapper .aside-container {
    margin-left: 0px; }
  .aside-toggled .wrapper .section-container,
  .aside-toggled .wrapper .footer-container {
    margin-left: 0px; }
  .aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .wrapper .section-container,
    .csstransforms3d .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(0px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-toggled .wrapper .footer-container {
    transform: translate3d(0px, 0, 0); }
  .csstransforms3d .aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

@media (max-width: 767.98px) {
  .aside-collapsed .wrapper .aside-container {
    margin-left: -70px; }
  .aside-collapsed.aside-toggled .wrapper .section-container,
  .aside-collapsed.aside-toggled .wrapper .footer-container {
    margin-left: 70px; }
  .aside-collapsed.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed .wrapper .section-container,
    .csstransforms3d .aside-collapsed .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .aside-collapsed .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(-70px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .footer-container {
    transform: translate3d(70px, 0, 0); }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

.aside-collapsed {
  overflow-y: auto; }
  .aside-collapsed .wrapper .aside-container,
  .aside-collapsed .wrapper .aside-container .aside-inner {
    width: 70px; }
  .aside-collapsed .wrapper .aside-container .nav-floating {
    margin-left: 70px; }

@media (min-width: 768px) {
  .aside-collapsed .wrapper .section-container,
  .aside-collapsed .wrapper .footer-container {
    margin-left: 70px; } }

@media (max-width: 767.98px) {
  .aside-collapsed-text .wrapper .aside-container {
    margin-left: -90px; }
  .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    margin-left: 90px; }
  .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed-text .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed-text .wrapper .section-container,
    .csstransforms3d .aside-collapsed-text .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .aside-collapsed-text .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(-90px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    transform: translate3d(90px, 0, 0); }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

.aside-collapsed-text {
  overflow-y: auto; }
  .aside-collapsed-text .wrapper .aside-container,
  .aside-collapsed-text .wrapper .aside-container .aside-inner {
    width: 90px; }
  .aside-collapsed-text .wrapper .aside-container .nav-floating {
    margin-left: 90px; }

@media (min-width: 768px) {
  .aside-collapsed-text .wrapper .section-container,
  .aside-collapsed-text .wrapper .footer-container {
    margin-left: 90px; } }

@media (max-width: 1140px) {
  .layout-fixed.aside-toggled, .layout-fixed.offsidebar-open {
    overflow-y: hidden; } }

.layout-fixed .wrapper {
  /* only applied to sidebar */ }
  .layout-fixed .wrapper .topnavbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 121; }
  .layout-fixed .wrapper .aside-container,
  .layout-fixed .wrapper .offsidebar {
    position: fixed; }
  .layout-fixed .wrapper .aside-container {
    /* safari fix */
    height: 1px;
    min-height: 100%;
    /* ios blanks space fix */ }
    .layout-fixed .wrapper .aside-container .aside-inner {
      position: fixed;
      top: 0;
      bottom: 0;
      -webkit-box-shadow: 0 1px 6px rgba(0, 21, 41, 0.08);
      box-shadow: 0 1px 6px rgba(0, 21, 41, 0.08); }
  .layout-fixed .wrapper .section-container {
    margin-top: 55px; }

/* IE10+ hack: safari fix breaks ie so we need to target ie only to restore */
_:-ms-lang(x),
.layout-fixed .wrapper .aside-container .aside-inner {
  position: static !important; }

@media (min-width: 992px) {
  .layout-boxed {
    overflow: auto !important; }
    .layout-boxed .wrapper {
      margin: 0 auto;
      overflow: hidden;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.25); }
      .layout-boxed .wrapper .offsidebar {
        position: absolute !important; }
      .layout-boxed .wrapper .aside-container {
        left: inherit; }
      .layout-boxed .wrapper,
      .layout-boxed .wrapper .topnavbar-wrapper {
        width: 970px; }
    .layout-boxed.layout-fixed .wrapper .aside-container .aside-inner {
      left: inherit; } }

@media (min-width: 1600px) {
  .layout-boxed .wrapper,
  .layout-boxed .wrapper .topnavbar-wrapper {
    width: 1140px; } }

.sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0px;
  z-index: 112; }
  .aside-collapsed .sidebar-backdrop {
    left: 70px; }
  .aside-collapsed-text .sidebar-backdrop {
    left: 90px; }

/* ========================================================================
   Component: layout-extra
 ========================================================================== */
.hidden-footer .wrapper .footer-container {
  display: none; }

.hidden-footer .wrapper .section-container {
  margin-bottom: 0 !important; }

.layout-fs .wrapper .section-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
  top: 55px;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow: hidden; }
  .layout-fs .wrapper .section-container .content-wrapper {
    width: 100%;
    height: 100%;
    padding: 0; }
    .layout-fs .wrapper .section-container .content-wrapper > * {
      width: 100%;
      height: 100%; }

.layout-fs.hidden-footer .wrapper .section-container {
  bottom: 0; }

.layout-h .wrapper .section-container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .layout-h .wrapper .section-container .content-wrapper .content-heading {
    display: none; }

.layout-h .wrapper .section-container {
  margin-top: 0; }

.layout-h .wrapper .offsidebar {
  margin-top: 55px; }

.layout-h .wrapper .footer-container {
  margin: 0; }

.layout-h.layout-fixed .wrapper .section-container,
.layout-h.layout-fixed .wrapper .offsidebar {
  margin-top: 55px; }

.aside-float .wrapper {
  box-shadow: 0 0 0 #000; }
  .aside-float .wrapper .footer-container {
    border: 0; }
  .aside-float .wrapper .aside-container {
    padding: 15px 0;
    background: transparent; }
    .aside-float .wrapper .aside-container .aside-inner {
      height: 100%;
      top: 15px;
      bottom: 15px;
      left: inherit; }
    .aside-float .wrapper .aside-container .sidebar {
      border: 1px solid #e4eaec; }
    .aside-float .wrapper .aside-container .sidebar:after {
      display: none; }
  @media (min-width: 768px) {
    .aside-float .wrapper .section-container {
      padding-left: 15px; }
    .aside-float .wrapper .footer-container {
      left: 15px; } }
  .aside-float .wrapper .content-wrapper {
    border: 0; }
    .aside-float .wrapper .content-wrapper .content-heading {
      padding-top: 30px;
      border: 0;
      background-color: transparent; }
    .aside-float .wrapper .content-wrapper .unwrap {
      margin: 0 !important; }

.aside-float.aside-toggled .wrapper .aside-container {
  transition: delay(0.05s); }
  .aside-float.aside-toggled .wrapper .aside-container .aside-inner {
    margin-left: 15px; }

.aside-float.aside-toggled .wrapper .section-container {
  padding-left: 15px; }

.aside-float.aside-toggled .wrapper .footer-container {
  left: 15px; }

@media (min-width: 768px) {
  .aside-float.aside-collapsed .topnavbar .navbar-header {
    width: 100px; }
  .aside-float.aside-collapsed-text .topnavbar .navbar-header {
    width: 120px; }
  .aside-float.layout-fs .wrapper .section-container .content-wrapper {
    padding: 0 0 0 20px; }
  .aside-float .wrapper .aside-container .aside-inner {
    margin-left: 15px; } }

@media (min-width: 992px) {
  .aside-float.layout-boxed .wrapper .aside-container .aside-inner {
    margin-left: 0; }
  .aside-float.layout-boxed .wrapper .section-container {
    padding-left: 0; } }

.aside-float.aside-toggled.layout-fs .wrapper .section-container .content-wrapper {
  padding: 0 0 0 20px; }

/* ========================================================================
   Component: layout-animation.less
 ========================================================================== */
.wrapper .aside-container {
  transition: width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1); }
  @media (prefers-reduced-motion: reduce) {
    .wrapper .aside-container {
      transition: none; } }

.aside-inner,
.navbar-header,
.sidebar > .sidebar-nav > li {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.wrapper .section-container {
  transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.sidebar > .sidebar-nav .badge {
  animation: fadeInRight 1s;
  animation-fill-mode: both; }

.aside-collapsed .sidebar > .sidebar-nav .badge,
.aside-collapsed-text .sidebar > .sidebar-nav .badge {
  animation: fadeIn 1s; }

.sidebar .sidebar-nav > li > a {
  animation: fadeInLeft .5s; }

.sidebar > .sidebar-nav > .nav-heading,
.sidebar > .sidebar-nav > li > a > span,
.navbar-brand .brand-logo {
  animation: fadeIn 1s; }

.sidebar li > a,
.sidebar li > .nav-item,
.sidebar > .sidebar-nav > .nav-heading {
  white-space: nowrap; }

.aside-collapsed .user-block-picture,
.aside-collapsed-text .user-block-picture {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

/* ========================================================================
     Component: top-navbar
 ========================================================================== */
.topnavbar-wrapper .nav-pills .nav-link {
  padding: 0 1rem;
  height: 55px;
  line-height: 55px;
  color: #fff; }

.topnavbar {
  -webkit-backface-visibility: hidden;
  /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  z-index: 1050;
  border: 0;
  padding: 0;
  min-height: 55px; }
  @media (min-width: 768px) {
    .topnavbar .navbar-header {
      width: 200px;
      text-align: center; }
      .topnavbar .navbar-header .navbar-brand {
        width: 100%; } }
  .topnavbar .left-nav {
    flex-direction: row;
    flex-wrap: wrap; }
    .topnavbar .left-nav .nav-item > .nav-link {
      border-radius: 0; }
      .topnavbar .left-nav .nav-item > .nav-link.active {
        font-weight: bold; }
      .topnavbar .left-nav .nav-item > .nav-link.active, .topnavbar .left-nav .nav-item > .nav-link:hover {
        background-color: inherit;
        border-bottom: 5px solid #f08200;
        color: #fff; }
    .topnavbar .left-nav .nav-item.dropdown.active {
      font-weight: bold; }
    .topnavbar .left-nav .nav-item.dropdown.show .dropdown-toggle.nav-link, .topnavbar .left-nav .nav-item.dropdown.active .dropdown-toggle.nav-link {
      background-color: inherit;
      border-bottom: 5px solid #f08200; }
    .topnavbar .left-nav .nav-item.dropdown .dropdown-menu {
      padding: 0;
      overflow: hidden; }
      .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button {
        background-color: #fff;
        padding: 0; }
        .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button:focus, .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button:active {
          background-color: inherit;
          color: #fff; }
          .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button:focus .nav-link, .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button:active .nav-link {
            color: #fff;
            background-color: #f08200; }
        .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button:hover {
          background-color: #f08200;
          color: #fff; }
          .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button:hover .nav-link {
            color: #fff;
            background-color: #f08200; }
        .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button .nav-link {
          border-radius: 0;
          background-color: inherit;
          white-space: nowrap;
          color: #656565; }
          .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button .nav-link.active {
            font-weight: bold; }
          .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button .nav-link.active, .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button .nav-link:focus, .topnavbar .left-nav .nav-item.dropdown .dropdown-menu button .nav-link:hover {
            background-color: #f08200;
            color: #fff; }

.topnavbar {
  position: relative; }
  .topnavbar .navbar-header {
    background-color: transparent; }
  .topnavbar .navbar-nav img.flag {
    width: 30px;
    border-radius: 2px; }
    .topnavbar .navbar-nav img.flag.japan {
      border: 1px solid #ddd; }
  .topnavbar .navbar-nav .avatar-wrapper {
    position: relative; }
    .topnavbar .navbar-nav .avatar-wrapper img {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 10px; }
    .topnavbar .navbar-nav .avatar-wrapper em {
      font-size: 24px;
      margin-top: 8px;
      padding-bottom: .5rem; }
  .topnavbar .navbar-header {
    position: relative;
    z-index: 11;
    padding-left: 2rem; }
    @media (min-width: 768px) {
      .topnavbar .navbar-header {
        padding-left: 0; } }
    .topnavbar .navbar-header .navbar-brand {
      padding: 0; }
    .topnavbar .navbar-header .brand-logo > img,
    .topnavbar .navbar-header .brand-logo-collapsed > img {
      margin: 0 auto; }
    .topnavbar .navbar-header .brand-logo {
      display: block;
      padding: 0px 15px; }
      .topnavbar .navbar-header .brand-logo .img-fluid {
        border-radius: 3px;
        height: 36px; }
    .topnavbar .navbar-header .brand-logo-collapsed {
      display: none;
      padding: 6px 15px; }
  .topnavbar .dropdown {
    position: static; }
    .topnavbar .dropdown .dropdown-menu {
      position: absolute;
      margin-top: 0;
      top: auto;
      left: 0;
      right: 0; }
  @media (min-width: 992px) {
    .topnavbar .dropdown {
      position: relative; }
      .topnavbar .dropdown .dropdown-menu {
        top: 54px;
        left: 0;
        right: auto; }
      .topnavbar .dropdown .dropdown-menu-right {
        right: 0;
        left: auto; } }
  .topnavbar > .navbar-nav .nav-item > .nav-link {
    padding: 1.1rem .95rem; }
  .topnavbar .navbar-nav > .nav-item > .navbar-text {
    color: #fff; }
  .topnavbar > .navbar-nav > .nav-item > .nav-link {
    font-size: .85rem; }
    .topnavbar > .navbar-nav > .nav-item > .nav-link.avatar-toggle {
      padding: .5rem 0.95rem; }
    .topnavbar > .navbar-nav > .nav-item > .nav-link .fa-expand {
      margin-top: 3px;
      font-size: 16px; }
  .topnavbar .navbar-nav > .nav-item > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link {
    color: #fff; }
  .topnavbar .navbar-nav > .nav-item.active > .nav-link, .topnavbar .navbar-nav > .nav-item.active > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.active > .nav-link:focus,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    background-color: transparent; }
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: 55px;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    transition: color 0.3s ease; }
    @media (prefers-reduced-motion: reduce) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        transition: none; } }
    @media (min-width: 768px) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        color: #fff; } }
  @media (max-width: 767.98px) {
    .topnavbar .navbar-text {
      margin: 10px; } }

.layout-h .topnavbar .navbar-header {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: left; }
  @media (min-width: 992px) {
    .layout-h .topnavbar .navbar-header {
      width: auto; } }
  .layout-h .topnavbar .navbar-header .navbar-brand {
    min-height: 55px; }

.layout-h .topnavbar .navbar-toggler {
  border: 0;
  margin-left: auto; }
  .layout-h .topnavbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }

@media (max-width: 991.98px) {
  .layout-h .topnavbar .dropdown-menu {
    left: 0 !important;
    right: 0 !important; } }

.layout-h .topnavbar .navbar-form {
  left: 0; }

@media (max-width: 767.98px) {
  .sidebar-toggle {
    position: absolute !important;
    top: 2px;
    left: 0;
    z-index: 3001; }
    .sidebar-toggle > em {
      color: white; } }

.topnavbar .navbar-form {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 55px;
  z-index: 9001;
  transition: all .3s;
  border: 0;
  border-bottom: 1px solid #e1e2e3; }
  .topnavbar .navbar-form .form-group {
    height: 100%;
    width: 100%; }
  .topnavbar .navbar-form .form-control {
    height: 100%;
    border: 0;
    border-radius: 0;
    width: 100%; }
  .topnavbar .navbar-form.open {
    top: 0; }
  .topnavbar .navbar-form .navbar-form-close {
    position: absolute;
    height: 30px;
    cursor: pointer;
    top: 50%;
    right: 0;
    margin-top: -15px;
    line-height: 30px;
    margin-right: 10px;
    color: #c1c2c3;
    font-size: 1.5em;
    pointer-events: auto; }

@media (min-width: 576px) {
  .topnavbar .navbar-form {
    left: 0px; } }

@media (min-width: 992px) {
  .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    transition: all 0.2s; } }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      transition: none; } }

@media (max-width: 767.98px) {
  .topnavbar .navbar-header {
    display: none; } }

@media (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed .topnavbar .navbar-header {
    width: 70px; }
  .aside-collapsed .topnavbar .navbar-form {
    left: 70px; } }

@media (min-width: 768px) {
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed-text .topnavbar .navbar-header {
    width: 90px; }
  .aside-collapsed-text .topnavbar .navbar-form {
    left: 90px; } }

/* ========================================================================
     Component: sidebar
 ========================================================================== */
.sidebar {
  height: 100%;
  padding-bottom: 20px;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
  .sidebar:after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px); }
  .sidebar .nav-heading {
    padding: 12px 15px;
    color: #919DA8;
    font-size: 13px;
    letter-spacing: .035em;
    pointer-events: none;
    cursor: default; }

.sidebar-nav {
  position: relative;
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .sidebar-nav > .nav-heading:first-child {
    padding-top: 20px; }
  .sidebar-nav .logo-container {
    text-align: center;
    padding: 25px; }
    .sidebar-nav .logo-container img {
      width: 175px; }
  .sidebar-nav > li {
    display: block;
    border-left: 3px solid transparent;
    transition: border-left-color 0.4s ease; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-nav > li {
        transition: none; } }
    .sidebar-nav > li > a,
    .sidebar-nav > li > .nav-item {
      position: relative;
      display: block;
      padding: 18px 16px;
      color: #515253;
      font-weight: normal;
      font-size: 13px;
      cursor: pointer; }
      .sidebar-nav > li > a:focus, .sidebar-nav > li > a:hover,
      .sidebar-nav > li > .nav-item:focus,
      .sidebar-nav > li > .nav-item:hover {
        text-decoration: none;
        outline: none;
        color: #f08200; }
      .sidebar-nav > li > a > em,
      .sidebar-nav > li > .nav-item > em {
        width: 1.8em;
        display: inline-block;
        font-style: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        color: inherits; }
      .sidebar-nav > li > a > img,
      .sidebar-nav > li > .nav-item > img {
        padding-right: 8px; }
    .sidebar-nav > li.active,
    .sidebar-nav > li.active > a,
    .sidebar-nav > li.active > .nav-item,
    .sidebar-nav > li.active .sidebar-nav, .sidebar-nav > li.open,
    .sidebar-nav > li.open > a,
    .sidebar-nav > li.open > .nav-item,
    .sidebar-nav > li.open .sidebar-nav {
      background-color: #fcfcfc;
      color: #f08200; }
    .sidebar-nav > li.active > .nav-item > em,
    .sidebar-nav > li.active > a > em, .sidebar-nav > li.open > .nav-item > em,
    .sidebar-nav > li.open > a > em {
      color: #f08200; }
    .sidebar-nav > li.active {
      border-left-color: #f08200; }
  .sidebar-nav .badge {
    display: block; }

@media only screen and (min-width: 1025px) {
  .sidebar:not(.show-scrollbar) {
    margin-right: -17px;
    overflow-y: scroll; } }

.sidebar-subnav {
  background-color: #fff; }
  .sidebar-subnav > .sidebar-subnav-header {
    color: #515253;
    display: none;
    padding: 10px 20px;
    font-weight: bold; }
  .sidebar-subnav > li {
    border-left: 0 !important; }
    .sidebar-subnav > li > a,
    .sidebar-subnav > li > .nav-item {
      display: block;
      position: relative;
      padding: 10px 20px;
      padding-left: 53px;
      font-weight: normal;
      background-color: transparent !important;
      color: #515253; }
      .sidebar-subnav > li > a:focus, .sidebar-subnav > li > a:hover,
      .sidebar-subnav > li > .nav-item:focus,
      .sidebar-subnav > li > .nav-item:hover {
        color: #f08200; }
      .sidebar-subnav > li > a > em,
      .sidebar-subnav > li > .nav-item > em {
        display: inline-block;
        width: 1.8em;
        margin: 0 0 0 -2em; }
    .sidebar-subnav > li.active > a,
    .sidebar-subnav > li.active > .nav-item {
      color: #f08200; }
      .sidebar-subnav > li.active > a:after,
      .sidebar-subnav > li.active > .nav-item:after {
        border-color: #f08200;
        background-color: #f08200; }
    .sidebar-subnav > li .sidebar-nav > li {
      padding-left: 5px; }
  .sidebar-subnav.nav-floating {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: -1px; }
    .sidebar-subnav.nav-floating,
    .sidebar-subnav.nav-floating .collapse, .sidebar-subnav.nav-floating .sidebar-subnav {
      height: auto !important;
      display: block !important;
      visibility: visible !important; }
      .sidebar-subnav.nav-floating.opening,
      .sidebar-subnav.nav-floating .collapse.opening, .sidebar-subnav.nav-floating .sidebar-subnav.opening {
        animation: none !important; }
    .sidebar-subnav.nav-floating > .sidebar-subnav-header {
      display: block; }
    .sidebar-subnav.nav-floating li > a,
    .sidebar-subnav.nav-floating li > .nav-item {
      padding-left: 20px; }
      .sidebar-subnav.nav-floating li > a em,
      .sidebar-subnav.nav-floating li > .nav-item em {
        margin-left: 0; }

@media (min-width: 768px) {
  .sidebar > .sidebar-nav .badge {
    margin: 2px 0 0 0; } }

.aside-collapsed .logo-container,
.aside-collapsed-text .logo-container {
  padding: 10px; }
  .aside-collapsed .logo-container img,
  .aside-collapsed-text .logo-container img {
    width: 55px; }

.aside-collapsed .sidebar,
.aside-collapsed-text .sidebar {
  overflow-x: hidden; }
  .aside-collapsed .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed .sidebar > .sidebar-nav > li > a > span,
  .aside-collapsed-text .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed-text .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
    display: none !important; }
  .aside-collapsed .sidebar > .sidebar-nav > li,
  .aside-collapsed-text .sidebar > .sidebar-nav > li {
    width: 69px; }
    .aside-collapsed .sidebar > .sidebar-nav > li > a,
    .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item {
      text-indent: -3px;
      padding: 20px 0;
      text-align: center; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > em {
        font-size: 1.6em;
        width: auto; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus {
        background-color: transparent; }
        .aside-collapsed .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus > em {
          color: inherit; }
  .aside-collapsed .sidebar .sidebar-nav .badge,
  .aside-collapsed-text .sidebar .sidebar-nav .badge {
    position: absolute;
    top: 10px;
    right: 5px;
    text-indent: 0; }

.aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
.aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
  display: block !important;
  font-size: 12px; }

.aside-collapsed-text .sidebar > .sidebar-nav > li {
  width: 88px; }
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a {
    padding: 14px 0; }
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em {
      font-size: 1.4em; }

/* ========================================================================
     Component: offsidebar
 ========================================================================== */
.offsidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 15px;
  margin-top: 55px;
  border-left: 1px solid #cccccc;
  background-color: #fff;
  color: #000;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1; }
  .offsidebar > .list-group {
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translateZ(0px); }
  .offsidebar .progress {
    border: 0; }
  .offsidebar .tab-content {
    padding: 0;
    border: 0; }
  .offsidebar .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .offsidebar .nav-tabs > .nav-item > .nav-link {
      background-color: transparent;
      border: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 0;
      color: #909FA7; }
      .offsidebar .nav-tabs > .nav-item > .nav-link.active {
        color: #5d9cec; }
  @media (min-width: 768px) {
    .offsidebar {
      margin-top: 55px; } }

.offsidebar-open .offsidebar {
  overflow-y: auto;
  z-index: 116; }

@media (min-width: 768px) {
  .offsidebar-open {
    overflow-y: auto; } }

.offsidebar {
  right: -15px; }

.no-csstransforms3d .offsidebar-open .offsidebar {
  right: 0; }

/* Transformation ready devices*/
.csstransforms3d .offsidebar {
  right: 0;
  transform: translate3d(15px, 0, 0);
  transition: transform .3s ease, z-index 0s linear .3s; }

.csstransforms3d .offsidebar-open .offsidebar {
  transform: translate3d(0, 0, 0);
  transition: transform .3s ease; }

/* ========================================================================
     Component: user-block
 ========================================================================== */
.has-user-block {
  display: block;
  overflow: hidden;
  border: 0 !important; }

.user-block {
  position: relative;
  padding: 25px 0 10px;
  cursor: pointer; }
  .user-block::after {
    display: block;
    clear: both;
    content: ""; }
  .user-block > .user-block-picture {
    position: relative;
    width: 60px;
    margin: 0 auto; }
    .user-block > .user-block-picture > img {
      max-width: 100%;
      height: auto; }
  .user-block .user-block-info {
    padding-top: 15px;
    text-align: center;
    white-space: nowrap; }
    .user-block .user-block-info .user-block-name, .user-block .user-block-info .user-block-role {
      display: block; }
    .user-block .user-block-info .user-block-name {
      color: #7D848F; }
    .user-block .user-block-info .user-block-role {
      font-size: 12px;
      color: #aaa; }

.user-block-status {
  position: relative; }
  .user-block-status > .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff; }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  padding: 15px 0 14px;
  margin: 0;
  text-align: center; }
  .aside-collapsed .user-block > .user-block-picture, .aside-collapsed-text .user-block > .user-block-picture {
    float: none;
    margin: 0  auto;
    width: 50px; }
    .aside-collapsed .user-block > .user-block-picture > .user-block-status, .aside-collapsed-text .user-block > .user-block-picture > .user-block-status {
      display: block; }
  .aside-collapsed .user-block .user-block-info, .aside-collapsed-text .user-block .user-block-info {
    display: none; }

.setting-color {
  padding: 0 5px; }
  .setting-color > label {
    display: block;
    position: relative;
    margin: 0 10px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .setting-color > label:first-child {
      margin-left: 0; }
    .setting-color > label:last-child {
      margin-right: 0; }
    .setting-color > label > .color {
      display: block;
      height: 18px; }
    .setting-color > label > .split {
      display: block; }
      .setting-color > label > .split::after {
        display: block;
        clear: both;
        content: ""; }
      .setting-color > label > .split > .color {
        display: block;
        height: 37.5px; }
        .setting-color > label > .split > .color:first-child {
          float: left;
          width: 70%; }
        .setting-color > label > .split > .color:last-child {
          float: right;
          width: 30%; }
    .setting-color > label > .icon-check {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -20px;
      margin-left: -10px;
      text-align: center;
      font-size: 1.33333333em;
      vertical-align: -15%;
      color: #fff;
      opacity: 0; }
    .setting-color > label > input[type="radio"] {
      position: absolute;
      opacity: 0;
      visibility: hidden; }
      .setting-color > label > input[type="radio"]:checked + .icon-check {
        opacity: 1 !important; }

.filter-container {
  margin-bottom: 15px; }

.el-pagination .el-pager li {
  height: 36px;
  line-height: 36px;
  min-width: 36px; }
  .el-pagination .el-pager li.btn-quicknext, .el-pagination .el-pager li.btn-quickprev {
    line-height: 36px; }

.el-pagination > .btn-next,
.el-pagination > .btn-prev {
  height: 36px;
  min-width: 36px; }

.el-select {
  width: 100%; }

.modal-title {
  font-size: 1.1rem; }

.pagination-right .el-pagination {
  padding: 0;
  text-align: right;
  margin-bottom: 20px; }

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  padding-left: 40px;
  height: 1.5em;
  opacity: .5;
  margin: 20px 0 20px; }
  .hr-text:before {
    content: '';
    background: #dcdfe6;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px; }
  .hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 1.5em;
    line-height: 1.5em;
    color: #000;
    font-weight: bold;
    background-color: #fff; }

.pull-right {
  float: right; }

.text-right {
  text-align: right; }

.scrollable-modal {
  max-height: 580px;
  overflow: auto; }

.arrow-container {
  border-left: 1px solid #ccc;
  border-collapse: collapse;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; }
  .arrow-container .arrows {
    margin: 0px;
    padding: 0px;
    width: 0;
    height: 0;
    border: none;
    border-left: 7px solid #0a0a0a00;
    border-right: 7px solid #b59d9d00;
    border-radius: 0; }
    .arrow-container .arrows:hover {
      cursor: pointer; }
  .arrow-container .arrow-down {
    border-top: 13px solid black; }
  .arrow-container .arrow-up {
    border-bottom: 13px solid black; }

.config-body {
  max-height: 540px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .config-body .el-checkbox__original {
    display: none; }
  .config-body .config-row {
    display: flex;
    border: 1px solid #ccc;
    border-bottom: 0px;
    width: 50%; }
    .config-body .config-row .config-data-num {
      flex: 1;
      border-right: 1px solid #ccc;
      text-align: center; }
    .config-body .config-row .config-data-name {
      flex: 7;
      padding-left: 5px; }

.config-display tr:last-child, .config-display tr:nth-child(21n) {
  border-bottom: 1px solid #ccc !important; }

.config-order tr:last-child, .config-order tr:nth-child(22n) {
  border-bottom: 1px solid #ccc !important; }

.settings-icon {
  position: relative;
  width: 20px;
  height: 20px;
  min-width: 20px;
  position: relative;
  margin-top: 8px;
  margin-left: 5px; }
  .settings-icon:hover {
    cursor: pointer; }
  .settings-icon img {
    position: absolute;
    width: 100%;
    height: 100%; }

.el-select-dropdown__item.selected .right-part {
  color: #e7e7e7; }

.el-select-dropdown__item .right-part {
  float: right;
  font-size: 13px;
  color: #8492a6; }

.async-select .right-part,
.basic-single .right-part {
  float: right;
  font-size: 13px;
  color: #8492a6; }
  .async-select .right-part.selected,
  .basic-single .right-part.selected {
    color: #e7e7e7; }

.el-card {
  margin-bottom: 20px;
  border: 1px solid #ebeef5 !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important; }

.box-center {
  margin: 0 auto;
  display: table; }

.modal-footer .el-form-item {
  margin-bottom: 0px; }

.react-draggable .modal-content {
  resize: both;
  overflow: auto;
  min-width: 520px;
  min-height: 350px; }

.react-draggable.modal-dialog {
  transition: none !important; }

.modal-content {
  border-radius: 2px; }
  .modal-content .modal-header {
    border-bottom: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
    .modal-content .modal-header .close {
      font-weight: 500; }
    .modal-content .modal-header button.close {
      background-color: #fff; }
  .modal-content .modal-footer {
    border-top: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px; }

label {
  margin-bottom: 0; }

.datepicker-block {
  width: 100%; }
  .datepicker-block .el-input.el-date-editor {
    width: 100%; }

.image-thumb {
  max-width: 80px;
  max-height: 50px; }

.select-search .el-icon-search {
  position: absolute;
  width: 35px;
  height: 100%;
  right: 0;
  top: 0;
  line-height: 36px;
  text-align: center;
  color: #bfcbd9;
  -webkit-transition: all .3s;
  transition: all .3s; }

.select-search .el-input__icon {
  cursor: pointer; }

.select-search input {
  padding-right: 35px; }

.pdf-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0; }

.cert-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh; }
  .cert-wrapper .pdf-container {
    z-index: -1; }

.el-tree.role-tree {
  border: 0px solid #d1dbe5; }

.remove-icon {
  display: flex;
  align-items: center; }
  .remove-icon button {
    margin-right: 8px; }
    .remove-icon button em {
      font-size: 1.4em; }

.el-button.button-add-icon {
  font-size: 18px; }

.nowrap {
  white-space: nowrap; }

.add-button {
  border: 1px solid black;
  border-radius: 4px;
  outline: none;
  margin: 5px; }

.timestampButton {
  flex: 1;
  min-width: 150px;
  max-width: 150px;
  border: 1px solid #ccc;
  border-right: none;
  background-color: #fff1cc;
  opacity: 0.8;
  cursor: pointer;
  transition: .2s all; }
  .timestampButton:hover {
    opacity: 1; }

.folder-row {
  padding: 5px;
  font-size: 16px; }
  .folder-row:hover {
    cursor: pointer;
    background-color: #f7f7f7; }
    .folder-row:hover .options {
      visibility: visible; }
  .folder-row .options {
    position: relative;
    visibility: hidden;
    padding: 5px;
    margin-left: 15px; }
    .folder-row .options i {
      padding: 5px; }
    .folder-row .options:hover .options-dropdown {
      visibility: visible; }
  .folder-row .options-dropdown {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    position: absolute;
    left: 0;
    width: max-content;
    background-color: #fff;
    border: 1px solid black;
    z-index: 100; }
    .folder-row .options-dropdown p {
      display: block;
      width: 100%;
      margin: 0px;
      padding: 5px 15px 5px 15px; }
      .folder-row .options-dropdown p:hover {
        background-color: black;
        color: #fff; }

.file-row {
  padding: 5px;
  font-size: 16px; }
  .file-row div:first-child {
    display: inline-block; }
  .file-row:hover {
    cursor: pointer;
    background-color: #f7f7f7; }
    .file-row:hover .options {
      visibility: visible; }
  .file-row .options {
    position: relative;
    visibility: hidden;
    padding: 5px;
    margin-left: 15px; }
    .file-row .options i {
      padding: 5px; }
    .file-row .options:hover {
      cursor: pointer; }
      .file-row .options:hover .options-dropdown {
        visibility: visible; }
  .file-row .options-dropdown {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    position: absolute;
    left: 0;
    width: max-content;
    background-color: #fff;
    border: 1px solid black;
    z-index: 100; }
    .file-row .options-dropdown p {
      display: block;
      width: 100%;
      margin: 0px;
      padding: 5px 15px 5px 15px; }
      .file-row .options-dropdown p:hover {
        background-color: black;
        color: #fff; }

.file-item {
  padding-left: 5px; }

.edit-content-container .file-item {
  color: #000000;
  text-decoration: underline; }
  .edit-content-container .file-item i {
    padding: 0 6px; }
  .edit-content-container .file-item:hover {
    color: #1b72e2;
    cursor: pointer;
    text-decoration: underline; }

.security-confirmation {
  max-width: 70%;
  font-size: 14px; }
  .security-confirmation p {
    font-size: 1.2rem; }
  .security-confirmation .modal-title {
    width: 100%; }
  .security-confirmation .modal-content {
    background-color: #ffffcc; }
  .security-confirmation .el-checkbox__inner {
    background-color: transparent; }
  .security-confirmation .error .el-checkbox__inner {
    border: 1px solid red; }
  .security-confirmation .modal-footer .el-checkbox__label {
    font-size: 1.2rem; }
  .security-confirmation .modal-footer .el-checkbox__inner {
    width: 1.2rem;
    height: 1.2rem; }

.custom-toggler {
  border: none;
  font-size: 1rem; }
  .custom-toggler:focus, .custom-toggler:active, .custom-toggler .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none; }

.vendor-table .el-table__column-filter-trigger {
  position: absolute;
  right: 5px;
  bottom: 5px;
  line-height: 26px;
  font-size: 16px; }
  .vendor-table .el-table__column-filter-trigger .filtered {
    color: #f08200; }

.vendor-table th {
  position: relative; }

/* ========================================================================
     Component: Page loader
 ========================================================================== */
.page-loader {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding-top: 30px;
  text-align: center; }

@media (min-width: 768px) {
  .section-container .page-loader {
    top: 55px;
    left: 0px; }
  .aside-collapsed .section-container .page-loader {
    left: 70px; }
  .aside-collapsed-text .section-container .page-loader {
    left: 90px; } }

/* ========================================================================
     Component: typo
 ========================================================================== */
body {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #000;
  font-size: 0.875rem; }

h1, h2, h3, h4 {
  font-weight: bold; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.65625rem; }

h1, .h1 {
  font-size: 2.25rem; }

h2, .h2 {
  font-size: 1.875rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: .875rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-bottom: .65rem; }

/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */
*:not(.react-grid-Cell):focus {
  outline: 0 !important; }

a {
  outline: none !important; }
  a.text-muted:hover, a.text-muted:focus {
    color: #748690; }

hr {
  border-top: 1px solid #e4eaec; }

[data-now] {
  display: inline-block; }

.badge {
  padding: 0.1875rem 0.4375rem;
  font-size: 0.75rem;
  border-radius: 0.625rem; }
  .badge.badge-warning {
    color: #fff !important; }
  .badge.badge-secondary {
    background-color: #3a3f51;
    color: #fff; }

.list-group {
  line-height: 1.3; }
  .list-group .list-group-item {
    padding: 10px;
    color: #555; }
    .list-group .list-group-item.active {
      color: #fff; }
      .list-group .list-group-item.active .badge {
        background-color: #fff;
        color: #5d9cec; }
    .card > .list-group .list-group-item {
      border: 0; }

.list-group + .card-footer {
  border-top: 0; }

.page-header {
  padding-bottom: 0.59375rem;
  margin: 2.625rem 0 1.3125rem;
  border-bottom: 1px solid #e4eaec; }

.card {
  border-color: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
  .card .card-header {
    border-bottom: 0;
    padding: 0.625rem 0.9375rem;
    background-color: transparent; }
    .card .card-header .card-title {
      margin-bottom: 0;
      font-size: 1rem; }
    .card .card-header a {
      text-decoration: none !important; }

.card-body {
  padding: 0.9375rem; }

.card-footer {
  padding: 0.625rem 0.9375rem; }

.card-default {
  border-top-width: 3px;
  border-color: #cfdbe2; }
  .card-default .card-header {
    background-color: #fff; }

.well {
  border: 1px solid #dde6e9; }

.jumbotron {
  border: 1px solid #dde6e9;
  background-color: #fff; }
  @media (min-width: 768px) {
    .jumbotron {
      padding: 2rem 1rem; } }

.nav-tabs > .nav-item > .nav-link {
  font-weight: bold;
  color: #000;
  background-color: #edf1f2;
  margin: 0;
  border: 1px solid #dde6e9;
  border-radius: 0;
  padding: 10px 20px; }
  .nav-tabs > .nav-item > .nav-link.active {
    background-color: #fff; }
    .nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:hover, .nav-tabs > .nav-item > .nav-link.active:focus {
      border-bottom-color: #fff; }

.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #dde6e9; }
  .nav-pills + .tab-content {
    border: 0;
    padding: 0; }
  .p-0 .tab-content {
    padding: 0 !important; }

.btn {
  border-radius: 3px;
  font-size: 13px;
  -webkit-appearance: none;
  outline: none !important;
  transition: all 0.1s; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn.btn-link {
    box-shadow: none;
    border: 0; }
  .input-group .btn {
    font-size: 14px;
    border-color: #dde6e9; }
  .input-group .form-control-sm + .input-group-btn .btn {
    font-size: 0.8125rem;
    padding: 0.3125rem 0.625rem;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.btn.btn-warning {
  color: #fff; }

.btn.btn-outline-warning:hover, .btn.btn-outline-warning:focus, .btn.btn-outline-warning:active {
  color: #fff; }

.btn-secondary {
  background-color: #fff;
  border-color: #eaeaea;
  color: #333; }
  .btn-secondary:hover {
    background-color: #f5f5f5; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #f5f5f5;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-outline-secondary {
  border-color: #eaeaea !important;
  color: #333; }
  .btn-outline-secondary:hover {
    color: #333;
    background-color: #f5f5f5 !important; }
  .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 234, 234, 0.5); }

span.btn {
  -webkit-appearance: none !important; }

.form-control {
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem; }
  .form-control:focus {
    border-color: #66afe9; }

.form-control-sm,
select.form-control-sm {
  font-size: .75rem;
  height: 31px; }

.custom-select {
  -webkit-appearance: none; }

.custom-control-label::before {
  top: 0.19rem;
  border: 1px solid #dde6e9; }

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px; }
  fieldset.last-child, fieldset:last-child {
    border-bottom: 0; }
  fieldset .form-group {
    margin-bottom: 0; }

.input-group-text {
  font-size: .875rem; }

@media (max-width: 767.98px) {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none; } }

.table-responsive {
  overflow-y: hidden; }

.table > thead > tr > th {
  border-bottom-width: 0; }

.table > tbody + tbody {
  border-bottom-width: 1px; }

.table .form-control {
  max-width: 100%; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px; }

.progress {
  border-radius: 0.2rem;
  border: 1px solid #e9ecef;
  background-color: #fff; }

.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid #e4eaec;
  border-radius: 0.2rem; }
  .popover .popover-title {
    border: 0; }

.nav.nav-pills .active > a {
  background-color: #5d9cec; }

.dropdown-menu {
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.3125rem 0;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  border-color: #e1e1e1; }
  .dropdown-menu .dropdown-item {
    line-height: 1.52857143;
    padding: 0.1875rem 1.25rem; }

.dropdown-header {
  color: #a1a2a3; }

.navbar-top .navbar-nav > .active > a {
  color: #999; }
  .navbar-top .navbar-nav > .active > a:hover, .navbar-top .navbar-nav > .active > a:focus {
    color: #d1d2d3; }

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #222; }

.carousel .carousel-indicators {
  bottom: 0; }

.carousel .carousel-control.left, .carousel .carousel-control.right {
  background-image: none; }

.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }

.alert-primary {
  color: #fff;
  background-color: #5d9cec;
  border-color: #5d9cec; }
  .alert-primary hr {
    border-top-color: #468ee9; }
  .alert-primary .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #fff;
  background-color: #fff;
  border-color: #fff; }
  .alert-secondary hr {
    border-top-color: #f2f2f2; }
  .alert-secondary .alert-link {
    color: #e6e6e6; }

.alert-success {
  color: #fff;
  background-color: #27c24c;
  border-color: #27c24c; }
  .alert-success hr {
    border-top-color: #23ad44; }
  .alert-success .alert-link {
    color: #e6e6e6; }

.alert-info {
  color: #fff;
  background-color: #23b7e5;
  border-color: #23b7e5; }
  .alert-info hr {
    border-top-color: #19a9d5; }
  .alert-info .alert-link {
    color: #e6e6e6; }

.alert-warning {
  color: #fff;
  background-color: #ff902b;
  border-color: #ff902b; }
  .alert-warning hr {
    border-top-color: #ff8312; }
  .alert-warning .alert-link {
    color: #e6e6e6; }

.alert-danger {
  color: #fff;
  background-color: #f05050;
  border-color: #f05050; }
  .alert-danger hr {
    border-top-color: #ee3939; }
  .alert-danger .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #000;
  border-color: #e4eaec; }

.topnavbar,
.navbar,
.navbar .dropdown-menu {
  filter: none !important; }

/* ========================================================================
     Component: bootstrap-custom
 ========================================================================== */
.container-sm {
  max-width: 540px;
  width: auto; }

.container-md {
  max-width: 720px;
  width: auto; }

.container-lg {
  max-width: 960px;
  width: auto; }

.row-flush {
  margin: 0; }
  .row-flush > .col,
  .row-flush > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #909FA7;
  padding: 10px 20px; }

.content-heading {
  justify-content: space-between; }
  .content-heading .breadcrumb {
    font-size: 0.8125rem;
    margin-bottom: 0; }
  .content-heading + .breadcrumb {
    margin: -25px -25px 20px -20px;
    background-color: #fff;
    border-top: 1px solid #cfdbe2;
    border-bottom: 1px solid #cfdbe2; }

.progress-sm {
  height: 15px; }

.progress-xs {
  height: 8px; }

.badge-inverse {
  color: #fff;
  background-color: #131e26; }
  a.badge-inverse:hover, a.badge-inverse:focus {
    color: #fff;
    background-color: #020304; }
  a.badge-inverse:focus, a.badge-inverse.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }

.badge-green {
  color: #fff;
  background-color: #37bc9b; }
  a.badge-green:hover, a.badge-green:focus {
    color: #fff;
    background-color: #2b957a; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }

.badge-pink {
  color: #fff;
  background-color: #f532e5; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #fff;
    background-color: #e90bd6; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #7266ba; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #564aa3; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }

.alert-purple {
  color: #fff;
  background-color: #7266ba;
  border-color: #7266ba; }
  .alert-purple hr {
    border-top-color: #6254b2; }
  .alert-purple .alert-link {
    color: #e6e6e6; }

.alert-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b; }
  .alert-green hr {
    border-top-color: #31a88b; }
  .alert-green .alert-link {
    color: #e6e6e6; }

.alert-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: #f532e5; }
  .alert-pink hr {
    border-top-color: #f41ae2; }
  .alert-pink .alert-link {
    color: #e6e6e6; }

.alert-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .alert-inverse hr {
    border-top-color: #0b1115; }
  .alert-inverse .alert-link {
    color: #e6e6e6; }

.form-control-rounded {
  border-radius: 100px; }

/* ========================================================================
     Component: button-extra
 ========================================================================== */
.btn-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .btn-inverse:hover {
    color: #fff;
    background-color: #060a0d;
    border-color: #020304; }
  .btn-inverse:focus, .btn-inverse.focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 64, 71, 0.5); }
  .btn-inverse.disabled, .btn-inverse:disabled {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
  .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-inverse.dropdown-toggle {
    color: #fff;
    background-color: #020304;
    border-color: black; }
    .btn-inverse:not(:disabled):not(.disabled):active:focus, .btn-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 64, 71, 0.5); }

.btn-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b; }
  .btn-green:hover {
    color: #fff;
    background-color: #2e9e83;
    border-color: #2b957a; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 198, 170, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #2b957a;
    border-color: #298b72; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 198, 170, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #7266ba;
  border-color: #7266ba; }
  .btn-purple:hover {
    color: #fff;
    background-color: #5b4eac;
    border-color: #564aa3; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 125, 196, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #564aa3;
    border-color: #52469b; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 125, 196, 0.5); }

.btn-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: #f532e5; }
  .btn-pink:hover {
    color: #fff;
    background-color: #f30ee0;
    border-color: #e90bd6; }
  .btn-pink:focus, .btn-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 81, 233, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #e90bd6;
    border-color: #dc0bcb; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 81, 233, 0.5); }

.btn-flat {
  border-bottom-width: 1px;
  border-radius: 0;
  box-shadow: 0 0 0 #000; }

.btn-xs {
  padding: 0.0625rem 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.5; }

.btn-xl {
  padding: 20px 16px;
  font-size: 18px; }

.btn-square {
  border-radius: 0; }

.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px; }

.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px; }

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0; }

.btn-label {
  position: relative;
  background: transparent;
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 0.375rem 1rem;
  left: -1rem;
  border-radius: 0.25rem 0 0 0.25rem; }
  .btn-label.btn-label-right {
    left: auto;
    right: -1rem;
    border-radius: 0 0.25rem 0.25rem 0; }

.btn-lg .btn-label {
  padding: 0.625rem 1.25rem;
  left: -1.25rem;
  border-radius: 0.375rem 0 0 0.375rem; }
  .btn-lg .btn-label.btn-label-right {
    left: auto;
    right: -1.25rem;
    border-radius: 0 0.375rem 0.375rem 0; }

.btn-sm .btn-label {
  padding: 0.3125rem 0.625rem;
  left: -0.625rem;
  border-radius: 0.2rem 0 0 0.2rem; }
  .btn-sm .btn-label.btn-label-right {
    left: auto;
    right: -0.625rem;
    border-radius: 0 0.2rem 0.2rem 0; }

.btn-xs .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 0.2rem 0 0 0.2rem; }
  .btn-xs .btn-label.btn-label-right {
    left: auto;
    right: -5px;
    border-radius: 0 0.2rem 0.2rem 0; }

.btn-fw {
  min-width: 80px; }
  .btn-fw.btn-sm {
    min-width: 40px; }
  .btn-fw.btn-md {
    min-width: 60px; }
  .btn-fw.btn-lg {
    min-width: 140px; }

.btn-circle {
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 12px;
  line-height: 35px;
  border-radius: 500px;
  padding: 0;
  border: 0; }
  .btn-circle > * {
    line-height: inherit !important; }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px; }

.btn-outline-inverse {
  color: #131e26;
  border-color: #131e26; }
  .btn-outline-inverse:hover {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
  .btn-outline-inverse:focus, .btn-outline-inverse.focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }
  .btn-outline-inverse.disabled, .btn-outline-inverse:disabled {
    color: #131e26;
    background-color: transparent; }
  .btn-outline-inverse:not(:disabled):not(.disabled):active, .btn-outline-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-outline-inverse.dropdown-toggle {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
    .btn-outline-inverse:not(:disabled):not(.disabled):active:focus, .btn-outline-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }

.btn-outline-green {
  color: #37bc9b;
  border-color: #37bc9b; }
  .btn-outline-green:hover {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #37bc9b;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }

.btn-outline-purple {
  color: #7266ba;
  border-color: #7266ba; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #7266ba;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }

.btn-outline-pink {
  color: #f532e5;
  border-color: #f532e5; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #f532e5;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }

/* ========================================================================
     Component: placeholder
 ========================================================================== */
.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed #ddd;
  background: #fafafa;
  color: #444; }

.box-placeholder > :last-child {
  margin-bottom: 0; }

.box-placeholder-lg {
  padding-top: 80px;
  padding-bottom: 80px; }

/* ========================================================================
     Component: cards
 ========================================================================== */
.card {
  margin-bottom: 1.25rem; }

.card .table {
  margin-bottom: 0; }
  .card .table > thead > tr > th {
    border-top: 0; }

.card.card-transparent {
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 #000; }
  .card.card-transparent .card-header,
  .card.card-transparent .card-body {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0; }

.card-flat {
  margin: 0 !important;
  border: 0; }

.card-columns-2 {
  column-count: 1; }
  @media (min-width: 768px) {
    .card-columns-2 {
      column-count: 2; } }

.card-tool {
  display: inline-block;
  color: #fff;
  text-align: center; }
  .card-tool:hover {
    cursor: pointer; }
  .card-tool > em + em {
    margin-left: 12px; }

.card-default .card-header > .card-tool {
  color: #c1c2c3; }

.card-header > .badge.float-right {
  margin-top: 3px; }
  .card-header > .badge.float-right + .badge.float-right {
    margin-right: 10px; }

.card-footer .pagination {
  margin: 0; }

.card-footer .radial-bar {
  margin-bottom: 0; }

.card-footer p {
  margin-bottom: 0; }

/* ========================================================================
     Component: circles
 ========================================================================== */
.circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 500px;
  margin: 0 .5em;
  background-color: #ddd;
  vertical-align: baseline;
  border: 2px solid transparent; }
  .circle.text-left {
    margin-left: 0; }
  .circle.text-right {
    margin-right: 0; }

.circle-sm {
  width: 5px;
  height: 5px; }

.circle-lg {
  width: 11px;
  height: 11px; }

.circle-xl {
  width: 18px;
  height: 18px; }

/* ========================================================================
     Component: dropdown-extras
 ========================================================================== */
.dropdown-list .dropdown-item {
  padding: 0; }

.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px; }

.dropdown-list .list-group {
  margin: 0; }

.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0; }
  .dropdown-list .list-group-item:first-child {
    border-top: 0; }
  .dropdown-list .list-group-item:last-child {
    border-bottom: 0; }

.dropdown > a {
  position: relative; }
  .dropdown > a > .badge {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 2px 5px; }

.dropdown-menu-right-forced {
  right: 0 !important;
  left: auto !important; }

.dropdown-toggle-nocaret:after {
  display: none; }

/* ========================================================================
     Component: half-float
 ========================================================================== */
.half-float {
  position: relative;
  margin-bottom: 69px; }
  .half-float .half-float-bottom,
  .half-float .half-float-top {
    position: absolute;
    left: 50%;
    bottom: -64px;
    width: 128px;
    height: 128px;
    margin-left: -64px;
    z-index: 2; }
  .half-float .half-float-top {
    bottom: auto;
    top: -64px; }

/* ========================================================================
     Component: animate
 ========================================================================== */
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

@keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort; }

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    -ms-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort; }

@-webkit-keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

@keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    -ms-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    -ms-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

.rag-fadeIn-enter {
  animation: fadeIn 0.5s; }

.rag-fadeIn-exit {
  display: none; }

.rag-fadeInRight-enter {
  animation: fadeInRight 0.5s; }

.rag-fadeInRight-exit {
  display: none; }

.rag-fadeInLeft-enter {
  animation: fadeInLeft 0.5s; }

.rag-fadeInLeft-exit {
  display: none; }

/* ========================================================================
     Component: slim-scroll
 ========================================================================== */
[data-scrollable] {
  display: block; }

.slimScrollBar {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.35) !important;
  border: 0 !important;
  border-radius: 1px !important; }

.slimScrollRail {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  border-radius: 0 !important;
  bottom: 0; }

/* ========================================================================
     Component: inputs
 ========================================================================== */
/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Note editor
 */
.c-checkbox,
.c-radio {
  margin-right: 4px; }
  .c-checkbox *,
  .c-radio * {
    cursor: pointer; }
  .c-checkbox input,
  .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important; }
  .c-checkbox span,
  .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 5px;
    text-align: center; }
    .c-checkbox span:before,
    .c-radio span:before {
      margin-left: 1px; }
  .c-checkbox:hover span,
  .c-radio:hover span {
    border-color: #5d9cec; }
  .form-inline .c-checkbox span, .form-inline
  .c-radio span {
    margin-left: 0; }
  .c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
  .c-radio.c-checkbox-rounded span,
  .c-radio.c-radio-rounded span {
    border-radius: 500px; }

/* override for radio */
.c-radio span {
  border-radius: 500px; }

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle; }

/* Checked state */
.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-checkbox input[type=checkbox]:checked + span:before,
  .c-checkbox input[type=radio]:checked + span:before,
  .c-radio input[type=checkbox]:checked + span:before,
  .c-radio input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-checkbox input[type=checkbox]:checked + span,
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=checkbox]:checked + span,
  .c-radio input[type=radio]:checked + span {
    border-color: #5d9cec;
    background-color: #5d9cec; }
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=radio]:checked + span {
    background-color: #fff; }
    .c-checkbox input[type=radio]:checked + span:before,
    .c-radio input[type=radio]:checked + span:before {
      color: #5d9cec; }
  .c-checkbox input[type=checkbox]:disabled + span,
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=checkbox]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-checkbox input[type=radio]:disabled + span:before,
    .c-radio input[type=radio]:disabled + span:before {
      color: #ddd; }

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    border-color: #5d9cec;
    background-color: #5d9cec; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    background-color: #fff; }
    .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
      background-color: #5d9cec; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-radio.c-radio-nofont input[type=radio]:disabled + span:before {
      background-color: #ddd; }

.switch .form-control {
  padding-top: 7px;
  margin-bottom: 0; }

.switch * {
  cursor: pointer; }

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1; }

.switch {
  display: flex;
  align-items: center;
  margin: 0; }
  .switch span {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 100px;
    transition: all .5s;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset; }
  .switch span:after {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #ddd;
    border-radius: 400px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all .2s; }
  .switch.switch-lg span {
    width: 50px;
    height: 25px; }
  .switch.switch-lg span:after {
    height: 23px;
    width: 23px; }
  .switch.switch-sm span {
    width: 30px;
    height: 15px; }
  .switch.switch-sm span:after {
    height: 13px;
    width: 13px; }

.switch input:checked + span {
  background-color: #5d9cec;
  border-color: #5d9cec;
  transition: all .5s; }

.switch input:checked + span:after {
  left: 50%;
  transition: all .2s; }

.switch input:disabled + span {
  background-color: #f1f1f1;
  cursor: not-allowed; }

.note-editor {
  background-image: -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
  background-image: linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: #fff;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none; }
  .note-editor.note-editor-margin {
    background-image: -webkit-linear-gradient(0deg, transparent 49px, #ffe0c4 49px, #ffe0c4 51px, transparent 51px), -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
    background-image: linear-gradient(90deg, transparent 49px, #ffe0c4 49px, #ffe0c4 51px, transparent 51px), linear-gradient(#eee 0.1em, transparent 0.1em);
    padding-left: 55px; }

/* ========================================================================
         Component: utils
 ========================================================================== */
.b0 {
  border-width: 0 !important; }

.bl0 {
  border-left-width: 0 !important; }

.br0 {
  border-right-width: 0 !important; }

.bt0 {
  border-top-width: 0 !important; }

.bb0 {
  border-bottom-width: 0 !important; }

.br, .b, .ba {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.bl, .b, .ba {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.bt, .b, .ba {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.bb, .b, .ba {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.text-primary {
  color: #5d9cec !important; }

.text-success {
  color: #27c24c !important; }

.text-info {
  color: #23b7e5 !important; }

.text-warning {
  color: #ff902b !important; }

.text-danger {
  color: #f05050 !important; }

.text-white {
  color: #fff !important; }

.text-inverse {
  color: #131e26 !important; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-pink {
  color: #f532e5 !important; }

.text-purple {
  color: #7266ba !important; }

.text-dark {
  color: #3a3f51 !important; }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-green {
  color: #37bc9b !important; }

.text-yellow {
  color: #fad732 !important; }

.text-gray-darker {
  color: #232735 !important; }

.text-gray-dark {
  color: #3a3f51 !important; }

.text-gray {
  color: #dde6e9 !important; }

.text-gray-light {
  color: #e4eaec !important; }

.text-gray-lighter {
  color: #edf1f2 !important; }

.text-inherit {
  color: inherit !important; }

.text-sm {
  font-size: 0.74375rem; }

.text-md {
  font-size: 1.6625rem; }

.text-lg {
  font-size: 2.625rem; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-normal {
  font-weight: normal !important; }

.text-bold {
  font-weight: bold !important; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.bg-primary {
  background-color: #5d9cec;
  color: #fff !important; }

.bg-primary-light {
  background-color: #8bb8f1;
  color: #fff !important; }

.bg-primary-dark {
  background-color: #2f80e7;
  color: #fff !important; }

.bg-primary small {
  color: inherit; }

.bg-success {
  background-color: #27c24c;
  color: #fff !important; }

.bg-success-light {
  background-color: #43d967;
  color: #fff !important; }

.bg-success-dark {
  background-color: #1e983b;
  color: #fff !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #23b7e5;
  color: #fff !important; }

.bg-info-light {
  background-color: #51c6ea;
  color: #fff !important; }

.bg-info-dark {
  background-color: #1797be;
  color: #fff !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #ff902b;
  color: #fff !important; }

.bg-warning-light {
  background-color: #ffab5e;
  color: #fff !important; }

.bg-warning-dark {
  background-color: #f77600;
  color: #fff !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #f05050;
  color: #fff !important; }

.bg-danger-light {
  background-color: #f47f7f;
  color: #fff !important; }

.bg-danger-dark {
  background-color: #ec2121;
  color: #fff !important; }

.bg-danger small {
  color: inherit; }

.bg-green {
  background-color: #37bc9b;
  color: #fff !important; }

.bg-green-light {
  background-color: #58ceb1;
  color: #fff !important; }

.bg-green-dark {
  background-color: #2b957a;
  color: #fff !important; }

.bg-green small {
  color: inherit; }

.bg-pink {
  background-color: #f532e5;
  color: #fff !important; }

.bg-pink-light {
  background-color: #f763eb;
  color: #fff !important; }

.bg-pink-dark {
  background-color: #e90bd6;
  color: #fff !important; }

.bg-pink small {
  color: inherit; }

.bg-purple {
  background-color: #7266ba;
  color: #fff !important; }

.bg-purple-light {
  background-color: #9289ca;
  color: #fff !important; }

.bg-purple-dark {
  background-color: #564aa3;
  color: #fff !important; }

.bg-purple small {
  color: inherit; }

.bg-inverse {
  background-color: #131e26;
  color: #fff !important; }

.bg-inverse-light {
  background-color: #243948;
  color: #fff !important; }

.bg-inverse-dark {
  background-color: #020304;
  color: #fff !important; }

.bg-inverse small {
  color: inherit; }

.bg-yellow {
  background-color: #fad732;
  color: #fff !important; }

.bg-yellow-light {
  background-color: #fbe164;
  color: #fff !important; }

.bg-yellow-dark {
  background-color: #f3ca06;
  color: #fff !important; }

.bg-yellow small {
  color: inherit; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-gray-darker {
  background-color: #232735;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #3a3f51;
  color: #fff !important; }

.bg-gray {
  background-color: #dde6e9;
  color: #000 !important; }

.bg-gray-light {
  background-color: #e4eaec;
  color: #000 !important; }

.bg-gray-lighter {
  background-color: #edf1f2;
  color: #000 !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-cover {
  background-size: cover; }

.thumb8 {
  width: 8px !important;
  height: 8px !important; }

.thumb16 {
  width: 16px !important;
  height: 16px !important; }

.thumb24 {
  width: 24px !important;
  height: 24px !important; }

.thumb32 {
  width: 32px !important;
  height: 32px !important; }

.thumb48 {
  width: 48px !important;
  height: 48px !important; }

.thumb64 {
  width: 64px !important;
  height: 64px !important; }

.thumb80 {
  width: 80px !important;
  height: 80px !important; }

.thumb96 {
  width: 96px !important;
  height: 96px !important; }

.thumb128 {
  width: 128px !important;
  height: 128px !important; }

.align-middle {
  vertical-align: middle; }

.align-top {
  vertical-align: top; }

.align-bottom {
  vertical-align: bottom; }

.bg-center {
  background-position: center center;
  background-size: cover; }

.list-icon em {
  font-size: 14px;
  width: 40px;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  text-align: center;
  transition: all 0.2s;
  line-height: 30px; }
  @media (prefers-reduced-motion: reduce) {
    .list-icon em {
      transition: none; } }

.list-icon div:hover em {
  transform: scale(3, 3); }

.d-table-fixed {
  table-layout: fixed; }

.wd-xxs {
  width: 60px; }

.wd-xs {
  width: 90px; }

.wd-sm {
  width: 150px; }

.wd-sd {
  width: 200px; }

.wd-md {
  width: 240px; }

.wd-lg {
  width: 280px; }

.wd-xl {
  width: 320px; }

.wd-xxl {
  width: 360px; }

.wd-wide {
  width: 100%; }

.wd-auto {
  width: auto; }

.wd-zero {
  width: 0; }

.m-xxs {
  margin: 5px !important; }

.m-xs {
  margin: 10px !important; }

.m-sm {
  margin: 15px !important; }

.m-sd {
  margin: 20px !important; }

.m-md {
  margin: 25px !important; }

.m-lg {
  margin: 30px !important; }

.m-xl {
  margin: 35px !important; }

.m-xxl {
  margin: 40px !important; }

.m-0 {
  margin: 0 !important; }

.mt-xxs {
  margin-top: 5px !important; }

.mt-xs {
  margin-top: 10px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mt-sd {
  margin-top: 20px !important; }

.mt-md {
  margin-top: 25px !important; }

.mt-lg {
  margin-top: 30px !important; }

.mt-xl {
  margin-top: 35px !important; }

.mt-xxl {
  margin-top: 40px !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-xxs {
  margin-right: 5px !important; }

.mr-xs {
  margin-right: 10px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mr-sd {
  margin-right: 20px !important; }

.mr-md {
  margin-right: 25px !important; }

.mr-lg {
  margin-right: 30px !important; }

.mr-xl {
  margin-right: 35px !important; }

.mr-xxl {
  margin-right: 40px !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-xxs {
  margin-bottom: 5px !important; }

.mb-xs {
  margin-bottom: 10px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.mb-sd {
  margin-bottom: 20px !important; }

.mb-md {
  margin-bottom: 25px !important; }

.mb-lg {
  margin-bottom: 30px !important; }

.mb-xl {
  margin-bottom: 35px !important; }

.mb-xxl {
  margin-bottom: 40px !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-xxs {
  margin-left: 5px !important; }

.ml-xs {
  margin-left: 10px !important; }

.ml-sm {
  margin-left: 15px !important; }

.ml-sd {
  margin-left: 20px !important; }

.ml-md {
  margin-left: 25px !important; }

.ml-lg {
  margin-left: 30px !important; }

.ml-xl {
  margin-left: 35px !important; }

.ml-xxl {
  margin-left: 40px !important; }

.ml-0 {
  margin-left: 0 !important; }

.p-xxs {
  padding: 5px !important; }

.p-xs {
  padding: 10px !important; }

.p-sm {
  padding: 15px !important; }

.p-sd {
  padding: 20px !important; }

.p-md {
  padding: 25px !important; }

.p-lg {
  padding: 30px !important; }

.p-xl {
  padding: 35px !important; }

.p-xxl {
  padding: 40px !important; }

.p-xxl {
  padding: 40px !important; }

.p-0 {
  padding: 0 !important; }

.pt-xxs {
  padding-top: 5px !important; }

.pt-xs {
  padding-top: 10px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pt-sd {
  padding-top: 20px !important; }

.pt-md {
  padding-top: 25px !important; }

.pt-lg {
  padding-top: 30px !important; }

.pt-xl {
  padding-top: 35px !important; }

.pt-xxl {
  padding-top: 40px !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-xxs {
  padding-right: 5px !important; }

.pr-xs {
  padding-right: 10px !important; }

.pr-sm {
  padding-right: 15px !important; }

.pr-sd {
  padding-right: 20px !important; }

.pr-md {
  padding-right: 25px !important; }

.pr-lg {
  padding-right: 30px !important; }

.pr-xl {
  padding-right: 35px !important; }

.pr-xxl {
  padding-right: 40px !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-xxs {
  padding-bottom: 5px !important; }

.pb-xs {
  padding-bottom: 10px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.pb-sd {
  padding-bottom: 20px !important; }

.pb-md {
  padding-bottom: 25px !important; }

.pb-lg {
  padding-bottom: 30px !important; }

.pb-xl {
  padding-bottom: 35px !important; }

.pb-xxl {
  padding-bottom: 40px !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-xxs {
  padding-left: 5px !important; }

.pl-xs {
  padding-left: 10px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pl-sd {
  padding-left: 20px !important; }

.pl-md {
  padding-left: 25px !important; }

.pl-lg {
  padding-left: 30px !important; }

.pl-xl {
  padding-left: 35px !important; }

.pl-xxl {
  padding-left: 40px !important; }

.pl-0 {
  padding-left: 0 !important; }

.clickable {
  cursor: pointer; }

.abs-center-container {
  position: relative; }

.abs-center {
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .abs-center.abs-fixed {
    position: fixed;
    z-index: 999; }
  .abs-center.abs-right {
    left: auto;
    right: 20px;
    text-align: right; }
  .abs-center.abs-left {
    right: auto;
    left: 20px;
    text-align: left; }

@media (max-height: 720px) {
  .abs-center {
    position: relative; } }

.link-unstyled {
  text-decoration: none !important;
  outline: none !important; }

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.ie-fix-flex {
  -ms-flex: 0 0 auto; }

/* ========================================================================
     Component: print
 ========================================================================== */
@media print {
  .sidebar,
  .topnavbar,
  .offsidebar,
  .btn {
    display: none !important;
    width: 0 !important;
    height: 0 !important; }
  .wrapper,
  .wrapper .section-container,
  .content-wrapper {
    margin: 0 !important;
    /* remove margin used for sidebar and expand the content */
    padding: 0 !important;
    width: 100% !important; }
  .content-wrapper {
    overflow: hidden !important; } }

.ck-content {
  min-height: 200px; }

a.cke_button__bold {
  display: none !important; }

.cke {
  flex: auto; }

.popup-content {
  width: auto !important;
  position: relative; }
  .popup-content div .el-button {
    bottom: 10px;
    left: 10px;
    right: 10px;
    position: absolute; }
  .popup-content div img {
    max-width: 800px;
    max-height: 600px;
    background: #f8f8f8; }

.status-bg-blue {
  background-color: #e0f4ff; }

.status-bg-green {
  background-color: #e2fff3; }

.status-bg-red {
  background-color: #fbf1f1; }

.status-bg-grey {
  background-color: #f8f8f8; }

.status-bg-white {
  background-color: #ffffff; }

.status-bg-orange {
  background-color: #ffe8d4; }

.status-bg-yellow {
  background-color: #fbf9ec; }

th.common-item {
  background-color: #d1d1d1 !important; }

td.common-item {
  background-color: #f9f9f9 !important; }

th.csr-item {
  background-color: #b5cbe7 !important; }

td.csr-item {
  background-color: #e9f3ff !important; }

td.csr-item.scap-warning-1, td.csr-item.evidence-warning-1 {
  background-color: #ffff9a !important; }

td.csr-item.scap-warning-2, td.csr-item.evidence-warning-2 {
  background-color: #ff8383 !important; }

th.mfg-process-item {
  background-color: #a6e3a6 !important; }

td.mfg-process-item {
  background-color: #ebffec !important; }

th.factory-examination-item {
  background-color: #ebaf7e !important; }

td.factory-examination-item {
  background-color: #ffebdb !important; }

table tr.status-bg-blue:hover td, table tr.status-bg-blue:hover td.freeze {
  background-color: #c9dee9; }

table tr.status-bg-blue td, table tr.status-bg-blue td.freeze {
  background-color: #e0f4ff; }

table tr.status-bg-green:hover td, table tr.status-bg-green:hover td.freeze {
  background-color: #abc8bc; }

table tr.status-bg-green td, table tr.status-bg-green td.freeze {
  background-color: #e2fff3; }

table tr.status-bg-red:hover td, table tr.status-bg-red:hover td.freeze {
  background-color: #d8cece; }

table tr.status-bg-red td, table tr.status-bg-red td.freeze {
  background-color: #fbf1f1; }

table tr.status-bg-yellow:hover td, table tr.status-bg-yellow:hover td.freeze {
  background-color: #c7c5b8; }

table tr.status-bg-yellow td, table tr.status-bg-yellow td.freeze {
  background-color: #fbf9ec; }

table tr.status-bg-orange:hover td, table tr.status-bg-orange:hover td.freeze {
  background-color: #cab4a1; }

table tr.status-bg-orange td, table tr.status-bg-orange td.freeze {
  background-color: #ffe8d4; }

table tr.status-bg-grey:hover td, table tr.status-bg-grey:hover td.freeze {
  background-color: #dadada; }

table tr.status-bg-grey td, table tr.status-bg-grey td.freeze {
  background-color: #f8f8f8; }

table tr.status-bg-white:hover td, table tr.status-bg-white:hover td.freeze {
  background-color: #f2f2f2; }

table tr.status-bg-white td, table tr.status-bg-white td.freeze {
  background-color: #ffffff; }

.scroll-grid {
  width: 100%;
  max-height: 100%;
  position: sticky;
  overflow: scroll; }
  .scroll-grid .top-line {
    width: 100%;
    height: 1px;
    background: #000000;
    z-index: 10000;
    top: 1px;
    position: sticky; }
  .scroll-grid .table th, .scroll-grid .table td {
    box-shadow: 0 0 0 2px #333; }
  .scroll-grid table {
    width: 100%;
    margin: auto; }
    .scroll-grid table td.freeze {
      box-shadow: 0 0 0 2px #333;
      z-index: 11; }
    .scroll-grid table th.freeze {
      box-shadow: 0 0 0 2px #333;
      z-index: 12;
      background: #fff; }
    .scroll-grid table .freeze.dark {
      box-shadow: 0 0 0 2px #333; }
    .scroll-grid table .freeze {
      left: 0;
      position: sticky;
      position: -webkit-sticky; }
    .scroll-grid table th:after,
    .scroll-grid table th:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%; }
    .scroll-grid table th:before {
      top: -1px; }
    .scroll-grid table th:after {
      bottom: -1px; }
    .scroll-grid table td.last:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%; }
    .scroll-grid table td.last:after {
      right: 1px; }
    .scroll-grid table td {
      padding: .65rem .4rem; }
    .scroll-grid table th {
      top: 1px;
      z-index: 10;
      position: sticky;
      background: #fff;
      vertical-align: top !important;
      padding: .65rem .4rem; }
      .scroll-grid table th .inner-wrap {
        height: 80px;
        position: relative; }
      .scroll-grid table th div {
        word-wrap: break-word; }
      .scroll-grid table th .el-table__column-filter-trigger {
        float: right;
        left: 10px;
        line-height: 26px;
        font-size: 16px; }
        .scroll-grid table th .el-table__column-filter-trigger .filtered {
          color: #f08200; }
      .scroll-grid table th .filter-triggers {
        height: 20px;
        position: absolute;
        bottom: 8px;
        width: 60px;
        right: 0; }

.popover.filter-content-container {
  z-index: 111;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  max-width: 360px; }
  .popover.filter-content-container .tree-view, .popover.filter-content-container .tree-view ul {
    list-style-type: none;
    padding: 5px 15px; }
    .popover.filter-content-container .tree-view:hover, .popover.filter-content-container .tree-view ul:hover {
      cursor: pointer; }
  .popover.filter-content-container .tree-view .el-checkbox {
    width: auto !important; }
  .popover.filter-content-container .popover-body {
    padding: 0; }
    .popover.filter-content-container .popover-body .el-checkbox {
      width: 100%; }
    .popover.filter-content-container .popover-body .el-table-filter__content {
      padding: 15px; }
      .popover.filter-content-container .popover-body .el-table-filter__content .el-input {
        margin-bottom: 20px; }
    .popover.filter-content-container .popover-body .el-checkbox + .el-checkbox {
      margin-left: 0; }
    .popover.filter-content-container .popover-body .el-table-filter__content {
      overflow: hidden; }
    .popover.filter-content-container .popover-body .checkboxes-list {
      max-height: 260px;
      overflow: auto; }

.popover.edit-content-container {
  z-index: 111;
  border: 1px solid #00000033;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  max-width: 500px; }
  .popover.edit-content-container .popover-body {
    padding: 0; }
    .popover.edit-content-container .popover-body .el-checkbox {
      width: 100%;
      margin-bottom: 5px; }
    .popover.edit-content-container .popover-body .el-table-edit__content {
      padding: 15px; }
      .popover.edit-content-container .popover-body .el-table-edit__content .el-input {
        margin-bottom: 20px; }
    .popover.edit-content-container .popover-body .el-checkbox + .el-checkbox {
      margin-left: 0; }
    .popover.edit-content-container .popover-body .el-table-edit__content {
      overflow: hidden; }
    .popover.edit-content-container .popover-body .el-table-edit__bottom {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 15px; }
      .popover.edit-content-container .popover-body .el-table-edit__bottom button {
        border: 1px solid black;
        padding: 5px;
        min-width: 60px;
        background-color: #fff; }
      .popover.edit-content-container .popover-body .el-table-edit__bottom button:last-child {
        background-color: #64b6ff; }
        .popover.edit-content-container .popover-body .el-table-edit__bottom button:last-child a {
          color: #fff; }
          .popover.edit-content-container .popover-body .el-table-edit__bottom button:last-child a:hover {
            text-decoration: none; }
    .popover.edit-content-container .popover-body .checkboxes-list {
      max-height: 260px;
      overflow: auto; }

.form-control {
  border: 1px solid #bfcbd9; }

.el-form-item .el-form-item__content {
  line-height: 1.52857; }
  .el-form-item .el-form-item__content > .el-switch,
  .el-form-item .el-form-item__content > .el-radio-group,
  .el-form-item .el-form-item__content > .el-checkbox {
    padding-top: 8px; }

.el-form-item .el-form-item__label {
  padding-bottom: 10px; }

.el-form-item.is-error .form-control {
  border-color: #f05050; }

.el-form-item.is-error .async-select > div {
  border-color: #f05050 !important; }
  .el-form-item.is-error .async-select > div:hover, .el-form-item.is-error .async-select > div:focus {
    border-color: #f05050; }

.login-container {
  width: 380px;
  margin-top: 5rem !important; }
  .login-container .card-body {
    margin-top: 20px; }
    .login-container .card-body .form-control {
      height: 2.6875rem; }
    .login-container .card-body .btn {
      padding: 0.575rem 1rem;
      font-size: 14px; }
  .login-container .title {
    margin-top: 50px; }

.profile-container .user-avatar {
  padding: 20px 0; }
  .profile-container .user-avatar .el-upload {
    text-align: unset; }

.profile-container .el-icon-upload2 {
  margin-top: 18px;
  color: #8492a6;
  font-size: 18px; }

.profile-container .pan-item {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: default;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

.profile-container .pan-info-roles-container {
  padding: 20px;
  text-align: center; }

.profile-container .pan-thumb {
  width: 100%;
  height: 100%;
  background-size: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  transform-origin: 95% 40%;
  transition: all 0.3s ease-in-out; }

.profile-container .pan-thumb:after {
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  top: 40%;
  left: 95%;
  margin: -4px 0 0 -4px;
  background: radial-gradient(ellipse at center, #0e0e0e 0%, #7d7e7d 100%);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.9); }

.profile-container .pan-info {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: inset 0 0 0 5px rgba(0, 0, 0, 0.05); }

.profile-container .pan-info h3 {
  color: #fff;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 2px;
  font-size: 18px;
  margin: 0 60px;
  padding: 22px 0 0 0;
  height: 85px;
  font-family: 'Open Sans', Arial, sans-serif;
  text-shadow: 0 0 1px #fff, 0 1px 2px rgba(0, 0, 0, 0.3); }

.profile-container .pan-info p {
  color: #fff;
  padding: 10px 5px;
  font-style: italic;
  margin: 0 30px;
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.5); }

.profile-container .pan-info p a {
  display: block;
  color: #333;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 1px;
  padding-top: 24px;
  margin: 7px auto 0;
  font-family: 'Open Sans', Arial, sans-serif;
  opacity: 0;
  transition: transform 0.3s ease-in-out 0.2s, opacity 0.3s ease-in-out 0.2s, background 0.2s linear 0s;
  transform: translateX(60px) rotate(90deg); }

.profile-container .pan-info p a:hover {
  background: rgba(255, 255, 255, 0.5); }

.profile-container .pan-item:hover .pan-thumb {
  transform: rotate(-110deg); }

.profile-container .pan-item:hover .pan-info p a {
  opacity: 1;
  transform: translateX(0px) rotate(0deg); }

.user-container .user-image {
  height: 180px;
  max-width: 360px; }

.user-list th {
  min-width: 105px;
  position: relative; }

.user-list .pagination-right {
  display: flex;
  justify-content: flex-end; }

.user-list .el-table__column-filter-trigger {
  position: absolute;
  right: 5px;
  bottom: 5px;
  line-height: 26px;
  font-size: 16px; }
  .user-list .el-table__column-filter-trigger .filtered {
    color: #f08200; }

.factory-evaluation .upload-demo .file-warning {
  top: -4px; }

.factory-evaluation .file-warning {
  font-size: 20px;
  position: absolute;
  right: -35px;
  top: 2px; }

.factory-evaluation .file-warning.invalid {
  color: red !important; }

.factory-evaluation .file-warning.double {
  color: #ebae62 !important; }

.factory-evaluation .datepicker-block .is-disabled input {
  color: #000;
  background: #f2f2f2; }

.factory-evaluation .basic-single {
  margin-bottom: 5px; }

.factory-evaluation .css-75ph1v-singleValue {
  color: unset !important; }

.factory-evaluation .has-select .basic-single {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 7px; }

.factory-evaluation td.has-buttons {
  position: relative;
  height: 0px;
  margin: 0;
  top: 0px;
  padding: 0 !important; }
  .factory-evaluation td.has-buttons div {
    width: 100% !important;
    height: 100%; }
  .factory-evaluation td.has-buttons .custom-button {
    position: absolute;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    line-height: 5;
    color: #000;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    width: 50%;
    height: calc(100% + 1px);
    margin-top: -1px; }
    .factory-evaluation td.has-buttons .custom-button:hover {
      color: #000; }
    .factory-evaluation td.has-buttons .custom-button:focus {
      outline: none;
      color: #000; }
    .factory-evaluation td.has-buttons .custom-button:disabled {
      background-color: #eef1f6; }
  .factory-evaluation td.has-buttons .confirm-button {
    background-color: #87d9f7;
    border-right: 1px solid #000; }
  .factory-evaluation td.has-buttons .update-button {
    background-color: #4471c4;
    border-right: 1px solid #000; }
  .factory-evaluation td.has-buttons .cancel-button {
    left: 50%;
    background-color: #ffb2b2; }

.factory-evaluation .audit-files {
  word-wrap: break-word; }

.factory-evaluation .file-item {
  color: #48576a; }
  .factory-evaluation .file-item i {
    color: #97a8be;
    padding: 0 6px; }
  .factory-evaluation .file-item:hover {
    color: #1b72e2;
    cursor: pointer;
    text-decoration: underline; }

.factory-evaluation .pagination-right {
  display: flex;
  justify-content: flex-end; }
  .factory-evaluation .pagination-right .freezepane-input {
    margin-right: 10px;
    width: 200px; }

.factory-evaluation .results-number {
  padding: 6px;
  color: #48576a;
  font-size: 1.1rem;
  font-weight: bold; }

.factory-evaluation .table td {
  overflow: hidden; }

.factory-evaluation .table th, .factory-evaluation .table td {
  padding: 0.75rem 0.4rem 0 0.4rem;
  word-wrap: break-word; }

.factory-evaluation .table td > div {
  word-wrap: break-word; }

.factory-evaluation .table .expanded {
  height: auto !important; }

.factory-evaluation .table-responsive > .table-bordered {
  margin-bottom: 0; }

.factory-evaluation .status-select .el-input .el-input__inner {
  background-color: #5d9cec;
  color: #fff;
  border: 1px solid #5d9cec; }

.factory-evaluation .status-select .el-input .el-input__icon {
  color: #fff; }

.factory-evaluation .el-card {
  padding-top: 25px;
  overflow: visible; }

.factory-evaluation .el-upload-list__item-name {
  overflow: visible; }

.factory-evaluation .el-upload-list {
  width: 305px; }
  .factory-evaluation .el-upload-list .el-upload-list__item-name:hover {
    cursor: pointer;
    text-decoration: underline; }

.factory-evaluation .upload-demo .el-upload-dragger {
  width: auto;
  min-width: 305px;
  padding: 0 15px; }

.factory-evaluation .upload-demo .el-upload-list {
  width: 305px; }

.factory-evaluation .upload-demo .el-upload__tip {
  margin-top: 0; }

.factory-evaluation .send-email-icon .fa {
  color: #5d9cec;
  cursor: pointer; }

.factory-evaluation .open-search {
  cursor: pointer;
  display: inline-block;
  margin: 0 0 10px 3px;
  border: 1px solid #bfcbd9;
  border-radius: 5px;
  width: 120px;
  height: 30px;
  position: relative;
  background-color: #ffffff;
  transition: all .3s;
  color: #bfcbd9;
  padding: 4px 0 0 8px; }
  .factory-evaluation .open-search i {
    position: absolute;
    width: 35px;
    height: 100%;
    right: 0;
    top: 6px;
    text-align: center;
    color: #bfcbd9;
    -webkit-transition: all .3s;
    transition: all .3s; }
  .factory-evaluation .open-search:hover {
    background-color: #20a0ff;
    color: #efefef; }
    .factory-evaluation .open-search:hover i {
      color: #efefef; }

.factory-evaluation #newFilters {
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  transition: 1s;
  border: 1px solid black;
  padding: 20px;
  margin: 0 0 10px 0; }
  .factory-evaluation #newFilters .search-datepicker .el-input {
    width: 130px; }

.factory-evaluation .evaluation-fee {
  position: relative;
  width: 100px;
  height: 68px; }
  .factory-evaluation .evaluation-fee .fee-paid,
  .factory-evaluation .evaluation-fee .fee-received {
    right: 5px;
    position: absolute; }
  .factory-evaluation .evaluation-fee .fee-paid {
    top: 10px; }
  .factory-evaluation .evaluation-fee .fee-received {
    bottom: 15px; }

.factory-evaluation .buttons-wrapper {
  display: flex;
  margin-bottom: 10px;
  margin-left: 160px; }

.invoice-rows td {
  position: relative; }

.invoice-rows td input {
  margin: auto;
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0; }

.invoice-status-confirmed {
  background: #3cde64; }

.invoice-status-inputting {
  background: #f3ebb3; }

.in-progress .invoice-status-input-completed {
  background: #dea726; }

.confirmed .invoice-status-input-completed {
  background: #3cde64; }

.invoice-status-submitted {
  background: #1b72e2; }

.invoice-status-waiting-submit, .invoice-status-confirming {
  background: #23b7e5; }

.fee-align-right {
  float: right;
  display: block; }

.draftmodal {
  font-size: 16px;
  min-width: 595px; }
  .draftmodal .section > .el-row {
    margin-bottom: 0px; }
  .draftmodal .section > div:first-child {
    margin-bottom: 50px; }
  .draftmodal .modal-content {
    height: 100%; }
  .draftmodal .draft-header {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    align-items: center; }
  .draftmodal .draft-date {
    margin: 0 5px; }
    .draftmodal .draft-date input, .draftmodal .draft-date .el-input {
      padding: 0;
      width: 100%; }
  .draftmodal .draft-input {
    padding: 0;
    background-color: transparent; }
  .draftmodal .area-input {
    padding: 0;
    width: 100%;
    resize: none;
    border: none;
    overflow: hidden;
    min-height: 23px;
    max-height: 100px;
    background-color: transparent; }
  .draftmodal .single-date input, .draftmodal .single-date .el-input {
    padding: 0;
    width: 100%; }

.cert-table {
  border-collapse: separate; }
  .cert-table th {
    top: 1px;
    z-index: 10;
    position: -webkit-sticky;
    position: sticky; }
  .cert-table th, .cert-table td {
    border: none;
    box-shadow: 0px 0px 0px 2px #000; }
  .cert-table td.freeze {
    position: sticky;
    position: -webkit-sticky;
    background-color: #fff;
    z-index: 998; }
  .cert-table th.freeze {
    position: sticky;
    position: -webkit-sticky;
    background-color: #fff;
    z-index: 999;
    top: 1px; }

.cert-preview .modal-content {
  resize: both;
  overflow: auto;
  min-width: 520px;
  min-height: 350px; }
  .cert-preview .modal-content .user-container {
    max-height: unset; }

.cert-table .el-table__column-filter-trigger {
  position: absolute;
  right: 5px;
  bottom: 5px;
  line-height: 26px;
  font-size: 16px; }
  .cert-table .el-table__column-filter-trigger .filtered {
    color: #f08200; }

.awaiting-cert {
  float: right;
  width: 20px;
  height: 20px; }

.buttons-container {
  padding: 0 20px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between; }

.results-number {
  font-weight: bold;
  font-size: 1.1rem; }

.registration-modal {
  min-width: 1085px; }

#printable {
  font-family: "arial" !important;
  font-weight: bold;
  font-size: 16px;
  max-height: unset;
  height: 1122px;
  overflow: hidden;
  padding: 55px 55px 0 55px;
  margin: 0; }
  #printable .el-row:nth-child(4) {
    font-size: 1.2em; }
  #printable .signature-line {
    border-bottom: 1px solid black;
    order: 1;
    margin-bottom: 10px; }
  #printable .section {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 80%; }
    #printable .section .cert-star {
      cursor: pointer; }
    #printable .section .el-row {
      font-size: 0.8em;
      margin-bottom: 10px; }
    #printable .section .flex-container {
      display: flex;
      flex-direction: column; }
  #printable input {
    width: 100%;
    border: none;
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: transparent !important;
    outline: transparent !important; }
    #printable input:hover {
      background-color: #0072ff24 !important; }
  #printable .el-input__icon {
    display: none; }
  #printable p {
    margin: 0 0 5px 0; }

.resend-confirmation div:not(:first-child) {
  text-align: center;
  padding: 15px 0 10px 0; }

.resend-confirmation button {
  float: right;
  margin: 0 10px 10px 10px; }

#section-to-print {
  max-width: unset;
  width: 800px; }
