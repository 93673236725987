.draftmodal {
    font-size: 16px;
    min-width: 595px;
    .section > .el-row {
        margin-bottom: 0px;
    }
    .section > div:first-child {
        margin-bottom: 50px;
    }
    .modal-content {
        height: 100%;
    }
    .draft-header {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        align-items: center;
    }
    .draft-date {
        margin: 0 5px;
        input, .el-input {
            padding: 0;
            width: 100%;
        }
    }
    .draft-input {
        padding: 0;
        background-color: transparent;
    }
    .area-input {
        padding: 0;
        width: 100%;
        resize: none;
        border: none;
        overflow: hidden;
        min-height: 23px;
        max-height: 100px;
        background-color: transparent;
    }
    .single-date {
        input, .el-input {
            padding: 0;
            width: 100%;
        }
    }
}

.cert-table {
    border-collapse: separate;
    th {
        top: 1px;
        z-index: 10;
        position: -webkit-sticky;
        position: sticky;
    }
    th, td {
        border: none;
        box-shadow: 0px 0px 0px 2px #000;
    }
    td.freeze {
        position: sticky;
        position: -webkit-sticky;
        background-color: #fff;
        z-index: 998;
    }

    th.freeze {
        position: sticky;
        position: -webkit-sticky;
        background-color: #fff;
        z-index: 999;
        top: 1px;
    }
}

.cert-preview {
    .modal-content {
        resize: both;
        overflow: auto;
        min-width: 520px;
        min-height: 350px;
        .user-container {
            max-height: unset;
        }
    }
}

.cert-table .el-table__column-filter-trigger {
  position: absolute;
  right: 5px;
  bottom: 5px;
  line-height: 26px;
  font-size: 16px;

  .filtered {
    color: $second-primary;
  }
}

.awaiting-cert {
    float: right;
    width: 20px;
    height: 20px;
}

.buttons-container {
    padding: 0 20px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.results-number {
    font-weight: bold;
    font-size: 1.1rem;
}

.registration-modal {
    min-width: 1085px;
}

#printable {
    font-family: "arial" !important;
    font-weight: bold;
    font-size: 16px;
    max-height: unset;
    height: 1122px;
    overflow: hidden;
    padding: 55px 55px 0 55px;
    margin: 0;
    .el-row:nth-child(4) {
        font-size: 1.2em;
    }
    .signature-line {
        border-bottom: 1px solid black;
        order: 1;
        margin-bottom: 10px;
    }
    .section {
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 80%;
        .cert-star {
            cursor: pointer
        }
        .el-row {
            font-size: 0.8em;
            margin-bottom: 10px;
        }
        .flex-container {
            display: flex;
            flex-direction: column;
        }
    }
    input {
        width: 100%;
        border: none;
        box-shadow: none !important;
        background-color: transparent!important;
        border-color: transparent!important;
        outline: transparent!important;
        &:hover {
            background-color: #0072ff24 !important;
        }
    }
    .el-input__icon {
        display: none;
    }
    p {
        margin: 0 0 5px 0;
    }
}
.resend-confirmation {
    div:not(:first-child) {
        text-align: center;
        padding: 15px 0 10px 0;
    }
    button {
        float: right;
        margin: 0 10px 10px 10px;
    }
}

#section-to-print {
    max-width: unset;
    width: 800px;
}
