.message-box {
    .modal-footer {
        border-top: 0;
    }
    .modal-header {
        border-bottom: 0;
        padding-bottom: 0;
        .modal-title {
            font-weight: bold;
        }
        .close {
            font-size: 1.8rem;
            font-weight: 400;
            text-shadow: none;
            padding: 0.5rem 1rem 1rem;
        }
    }
}