.login-container {
    width: 380px;
    margin-top: 5rem !important;
    .card-body {
        margin-top: 20px;
        .form-control {
            height: 2.6875rem;
        }
        .btn {
            padding: 0.575rem 1rem;
            font-size: 14px;
        }
    }
    .title {
        margin-top: 50px;
    }
}