.factory-evaluation {
	.upload-demo {
		.file-warning {
			top: -4px;
		}
	}
	.file-warning {
		font-size: 20px;
		position: absolute;
		right: -35px;
		top: 2px;
	}

	.file-warning.invalid {
		color: red !important;
	}

	.file-warning.double {
		color: #ebae62 !important;
	}

    .datepicker-block .is-disabled input {
        color: #000;
        background: #f2f2f2;
    }

    .basic-single {
        margin-bottom: 5px;
    }

    .css-75ph1v-singleValue {
        color: unset !important;
    }

    .has-select .basic-single {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 7px;
    }

	td.has-buttons {
		position: relative;
		height: 0px;
		margin: 0;
		top: 0px;
		padding: 0 !important;
		div {
			width: 100% !important;
			height: 100%;
		}
		.custom-button {
			position: absolute;
			display: inline-flex;
			align-items: flex-start;
			justify-content: center;
			line-height: 5;
			color: #000;
			margin: 0;
			padding: 0;
			border: none;
			border-radius: 0;
			width: 50%;
			height: calc(100% + 1px);
			margin-top: -1px;
			&:hover {
				color: #000;
			}
			&:focus {
				outline: none;
				color: #000;
			}
			&:disabled {
				background-color: #eef1f6;
			}
		}

		.confirm-button {
			background-color: #87d9f7;
			border-right: 1px solid #000;
		}
		.update-button {
			background-color: #4471c4;
			border-right: 1px solid #000;
		}
		.cancel-button {
			left: 50%;
			background-color: #ffb2b2;
		}
	}

    .audit-files {
        word-wrap: break-word;
    }

    .file-item {
        color: #48576a;
        i {
            color: #97a8be;
            padding: 0 6px;
        }
        &:hover {
            color: #1b72e2;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .pagination-right {
        display: flex;
        justify-content: flex-end;
        .freezepane-input {
            margin-right: 10px;
            width: 200px;
        }
    }
    .results-number {
        padding: 6px;
        color: #48576a;
        font-size: 1.1rem;
        font-weight: bold;
    }
    .table {
        td {
            overflow: hidden;
        }
        th, td {
            padding: 0.75rem 0.4rem 0 0.4rem;
            word-wrap: break-word;
        }
        td > div {
            word-wrap: break-word;
        }
      .expanded {
        height: auto !important;
      }
    }
    .table-responsive > .table-bordered {
        margin-bottom: 0;
    }
    .status-select {
        .el-input {
            .el-input__inner {
                background-color: $primary;
                color: #fff;
                border: 1px solid $primary
            }
            .el-input__icon {
                color: #fff;
            }
        }
    }
    .el-card {
        padding-top: $m-md;
        overflow: visible;
    }

	.el-upload-list__item-name {
		overflow: visible;
	}

    .el-upload-list {
		width: 305px;
        .el-upload-list__item-name {
            &:hover {
                cursor:pointer;
                text-decoration: underline;
            }
        }
    }

    .upload-demo {
        .el-upload-dragger {
            width: auto;
            min-width: 305px;
            padding: 0 15px;
        }
        .el-upload-list {
            width: 305px;
        }
        .el-upload__tip {
            margin-top: 0;
        }
    }
    .send-email-icon .fa {
        color: #5d9cec;
        cursor: pointer;
    }
    .open-search {
        cursor: pointer;
        display: inline-block;
        margin: 0 0 10px 3px;
        border: 1px solid #bfcbd9;
        border-radius: 5px;
        width: 120px;
        height: 30px;
        position: relative;
        background-color: #ffffff;
        transition: all .3s;
        color: #bfcbd9;
        padding: 4px 0 0 8px;
        i {
            position: absolute;
            width: 35px;
            height: 100%;
            right: 0;
            top: 6px;
            text-align: center;
            color: #bfcbd9;
            -webkit-transition: all .3s;
            transition: all .3s;
        }
        &:hover {
            background-color: #20a0ff;
            color: #efefef;
            i {
                color: #efefef;
            }
        }
    }
    #newFilters {
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        transition: 1s;
        border: 1px solid black;
        padding: 20px;
        margin: 0 0 10px 0;
        .search-datepicker {
            .el-input {
                width: 130px;
            }
        }
    }
	.evaluation-fee {
		position: relative;
		width: 100px;
		height: 68px;
		.fee-paid,
		.fee-received {
			right: 5px;
			position: absolute;
		}
		.fee-paid {
			top: 10px;
		}
		.fee-received {
			bottom: 15px;
		}
	}
	.buttons-wrapper {
			display: flex;
			margin-bottom: 10px;
			margin-left: 160px;
	}
}
