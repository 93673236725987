.invoice-rows td {
	position: relative;
}

.invoice-rows td input {
	margin: auto;
	position: absolute;
	top: 0;
	right: 5px;
	bottom: 0;
}

.invoice-status-confirmed {
	background: #3cde64;
}

.invoice-status-not-started-yet {

}

.invoice-status-inputting {
	background: #f3ebb3;
}

.in-progress .invoice-status-input-completed {
	background: #dea726;
}

.confirmed .invoice-status-input-completed {
	background: #3cde64;
}

.invoice-status-submitted {
	background: #1b72e2;
}

.invoice-status-waiting-submit, .invoice-status-confirming {
	background: #23b7e5;
}

.fee-align-right {
	float: right;
	display: block;
}
