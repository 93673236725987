.popup-content {
	width: auto !important;
	position: relative;

	div {
		.el-button {
			bottom: 10px;
			left: 10px;
			right: 10px;
			position: absolute;
		}

		img {
			max-width: 800px;
			max-height: 600px;
			background: #f8f8f8;
		}
	}
}
