.form-control {
    border: 1px solid #bfcbd9;
}
.el-form-item {
    .el-form-item__content {
        line-height: 1.52857;
        >.el-switch,
        >.el-radio-group,
        >.el-checkbox {
            padding-top: 8px;
        }
    }
    .el-form-item__label {
        padding-bottom: 10px;
    }
    &.is-error {
        .form-control {
            border-color: $danger;
        }
        .async-select {
            > div {
                border-color: $danger !important;
                &:hover, &:focus {
                    border-color: $danger;
                }
            }
        }
    }
}