$grid-border-shadow-color: #333;

.scroll-grid {
	width: 100%;
	max-height: 100%;
	position: sticky;
	overflow: scroll;

	.top-line {
		width: 100%;
		height: 1px;
		background: #000000;
		z-index: 10000;
		top: 1px;
		position: sticky;
	}

	.table th, .table td {
		box-shadow: 0 0 0 2px $grid-border-shadow-color;
	}

	table {
		width: 100%;
		margin: auto;

		td.freeze {
			box-shadow: 0 0 0 2px $grid-border-shadow-color;
			z-index: 11;
		}

		th.freeze {
			box-shadow: 0 0 0 2px $grid-border-shadow-color;
			z-index: 12;
			background: #fff;
		}

		.freeze.dark {
			box-shadow: 0 0 0 2px $grid-border-shadow-color;
		}

		.freeze {
			left: 0;
			position: sticky;
			position: -webkit-sticky;
		}

		th:after,
		th:before {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
		}

		th:before {
			top: -1px;
		}

		th:after {
			bottom: -1px;
		}

		td.last:after {
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
		}

		td.last:after {
			right: 1px;
		}

		td {
			padding: .65rem .4rem;
		}

		th {
			top: 1px;
			z-index: 10;
			position: sticky;
			background: #fff;
			vertical-align: top !important;
			padding: .65rem .4rem;

			.inner-wrap {
				height: 80px;
				position: relative;
			}

			div {
				word-wrap: break-word;
			}

			.el-table__column-filter-trigger {
				float: right;
				left: 10px;
				line-height: 26px;
				font-size: 16px;

				.filtered {
					color: $second-primary;
				}
			}

			.filter-triggers {
				height: 20px;
				position: absolute;
				bottom: 8px;
				width: 60px;
				right: 0;

			}
		}
	}
}

.popover.filter-content-container {
	z-index: $z-index-main-section;
	border: 0;
	box-shadow: none;
	border-radius: 0;
	padding: 0;
	max-width: 360px;

	.tree-view, .tree-view ul {
	    list-style-type: none;
	    padding: 5px 15px;
	    &:hover {
	        cursor: pointer;
	    }
	}
	.tree-view .el-checkbox {
	    width: auto !important;
	}

	.popover-body {
		padding: 0;

		.el-checkbox {
			width: 100%;
		}

		.el-table-filter__content {
			padding: 15px;

			.el-input {
				margin-bottom: 20px;
			}
		}

		.el-checkbox + .el-checkbox {
			margin-left: 0;
		}

		.el-table-filter__content {
			overflow: hidden;
		}

		.checkboxes-list {
			max-height: 260px;
			overflow: auto;
		}
	}
}
.popover.edit-content-container {
	z-index: $z-index-main-section;
	border: 1px solid #00000033;
	box-shadow: none;
	border-radius: 0;
	padding: 0;
	max-width: 500px;

	.popover-body {
		padding: 0;

		.el-checkbox {
			width: 100%;
			margin-bottom: 5px;
		}

		.el-table-edit__content {
			padding: 15px;

			.el-input {
				margin-bottom: 20px;
			}
		}

		.el-checkbox + .el-checkbox {
			margin-left: 0;
		}

		.el-table-edit__content {
			overflow: hidden;
		}

		.el-table-edit__bottom {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			margin-top: 15px;
			button {
				border: 1px solid black;
				padding: 5px;
				min-width: 60px;
				background-color: #fff;
			}
			button:last-child {
				background-color: #64b6ff;
				a {
				color: #fff;
				&:hover {
					text-decoration: none;
				}
				}
			}
		}

		.checkboxes-list {
			max-height: 260px;
			overflow: auto;
		}
	}
}
