.filter-container {
    margin-bottom: 15px;
}
.el-pagination {
    .el-pager li {
        height: 36px;
        line-height: 36px;
        min-width: 36px;
        &.btn-quicknext,
        &.btn-quickprev {
            line-height: 36px;
        }
    }
    > .btn-next,
    > .btn-prev {
        height: 36px;
        min-width: 36px;
    }
}
.el-select {
    width: 100%;
}
.modal-title {
    font-size: 1.1rem;
}
.pagination-right {
    .el-pagination {
        padding: 0;
        text-align: right;
        margin-bottom: 20px;
    }
}
.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    // text-align: center;
    padding-left: 40px;
    height: 1.5em;
    opacity: .5;
    margin: 20px 0 20px;
    &:before {
        content: '';
        // use the linear-gradient for the fading effect
        // use a solid background color for a solid bar
        background: #dcdfe6;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }
    &:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        padding: 0 1.5em;
        line-height: 1.5em;
        color: #000;
        font-weight: bold;
        background-color: #fff;
    }
}
.pull-right {
    float: right;
}
.text-right {
    text-align: right;
}
.scrollable-modal {
    max-height: 580px;
    overflow: auto;
}

.arrow-container {
	border-left: 1px solid #ccc;
	border-collapse: collapse;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	.arrows {
	    margin: 0px;
	    padding: 0px;
	    width: 0;
	    height: 0;
	    border: none;
	    border-left: 7px solid #0a0a0a00;
	    border-right: 7px solid #b59d9d00;
	    border-radius: 0;
	    &:hover {
	        cursor: pointer;
	    }
	}
	.arrow-down {
	    border-top: 13px solid black;
	}
	.arrow-up {
	    border-bottom: 13px solid black;
	}
}

.config-body {
	max-height: 540px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	.el-checkbox__original {
		display: none;
	}
	.config-row {
		display: flex;
		border: 1px solid #ccc;
		border-bottom: 0px;
		width: 50%;
		.config-data-num {
			flex: 1;
			border-right: 1px solid #ccc;
			text-align: center;
		}
		.config-data-name {
			flex: 7;
			padding-left: 5px;
		}
	}

}

.config-display {
	tr:last-child, tr:nth-child(21n) {
		border-bottom: 1px solid #ccc !important;
	}
}
.config-order {
	tr:last-child, tr:nth-child(22n) {
		border-bottom: 1px solid #ccc !important;
	}
}

.settings-icon {
    position: relative;
    width: 20px;
    height: 20px;
    min-width: 20px;
    position: relative;
    margin-top: 8px;
    margin-left: 5px;
    &:hover {
        cursor: pointer;
    }
    img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
.el-select-dropdown__item {
    &.selected {
        .right-part {
        color: #e7e7e7;
        }
    }
    .right-part {
        float: right;
        font-size: 13px;
        color: #8492a6;
    }
}
.async-select,
.basic-single {
    .right-part {
        float: right;
        font-size: 13px;
        color: #8492a6;
        &.selected {
            color: #e7e7e7;
        }
    }
}
.el-card {
    margin-bottom: 20px;
    border: 1px solid #ebeef5 !important;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1) !important;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1) !important;
}
.box-center {
    margin: 0 auto;
    display: table;
}
.modal-footer {
    .el-form-item {
        margin-bottom: 0px;
    }
}
.react-draggable .modal-content {
    resize: both;
    overflow: auto;
    min-width: 520px;
    min-height: 350px;
}

.react-draggable.modal-dialog {
    transition: none !important;
}

.modal-content {
    border-radius: 2px;
    .modal-header {
        border-bottom: 0;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        .close {
            font-weight: 500;
        }
        button.close {
            background-color: #fff;
        }
    }
    .modal-footer {
        border-top: 0;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
    }
}
label {
    margin-bottom: 0;
}
.datepicker-block {
    width: 100%;
    .el-input.el-date-editor {
        width: 100%;

    }
}
.image-thumb {
    max-width: 80px;
    max-height: 50px;
}

.select-search {
    .el-icon-search {
        position: absolute;
        width: 35px;
        height: 100%;
        right: 0;
        top: 0;
        line-height: 36px;
        text-align: center;
        color: #bfcbd9;
        -webkit-transition: all .3s;
        transition: all .3s;
    }
    .el-input__icon {
        cursor: pointer;
    }
    input {
        padding-right: 35px;
    }
}
.pdf-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
}
.cert-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    .pdf-container {
        z-index: -1;
    }
}
.el-tree {
    &.role-tree {
        border: 0px solid #d1dbe5;
    }
}
.remove-icon {
    display : flex;
    align-items : center;
    button {
        margin-right: 8px;
        em {
            font-size: 1.4em;
        }
    }
}
.el-button.button-add-icon {
    font-size: 18px;
}
.nowrap {
    white-space: nowrap;
}
.add-button {
    border: 1px solid black;
    border-radius: 4px;
    outline: none;
    margin: 5px;
}
.timestampButton {
  flex: 1;
  min-width: 150px;
  max-width: 150px;
  border: 1px solid #ccc;
  border-right: none;
  background-color: #fff1cc;
  opacity: 0.8;
  cursor: pointer;
  transition: .2s all;
  &:hover {
    opacity: 1;
  }
}

.folder-row  {
    padding: 5px;
    font-size: 16px;
    &:hover {
        cursor: pointer;
        background-color: #f7f7f7;
        .options {
            visibility: visible;
        }
    }
    .options {
        position: relative;
        visibility: hidden;
        padding: 5px;
        margin-left: 15px;
        i {
            padding: 5px;
        }
        &:hover {
            .options-dropdown {
                visibility: visible;
            }
        }
    }
    .options-dropdown {
        display: flex;
        flex-direction: column;
        visibility: hidden;
        position: absolute;
        left: 0;
        width: max-content;
        background-color: #fff;
        border: 1px solid black;
        z-index: 100;
        p {
            display: block;
            width: 100%;
            margin: 0px;
            padding: 5px 15px 5px 15px;
            &:hover {
                background-color: black;
                color: #fff;
            }
        }
    }
}
.file-row {
    padding: 5px;
    font-size: 16px;
    div:first-child {
      display: inline-block;
    }
    &:hover {
        cursor: pointer;
        background-color: #f7f7f7;
        .options {
            visibility: visible;
        }
    }
    .options {
        position: relative;
        visibility: hidden;
        padding: 5px;
        margin-left: 15px;
        i {
            padding: 5px;
        }
        &:hover {
            cursor: pointer;
            .options-dropdown {
                visibility: visible;
            }
        }
    }
    .options-dropdown {
        display: flex;
        flex-direction: column;
        visibility: hidden;
        position: absolute;
        left: 0;
        width: max-content;
        background-color: #fff;
        border: 1px solid black;
        z-index: 100;
        p {
            display: block;
            width: 100%;
            margin: 0px;
            padding: 5px 15px 5px 15px;
            &:hover {
                background-color: black;
                color: #fff;
            }
        }
    }
}

.file-item {
	padding-left: 5px;
}

.edit-content-container {
	.file-item {
		color: #000000;
		text-decoration: underline;
		i {
			padding: 0 6px;
		}
		&:hover {
			color: #1b72e2;
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.security-confirmation {
    max-width: 70%;
    font-size: 14px;
    p {
        font-size: 1.2rem;
    }
    .modal-title {
        width: 100%;
    }
    .modal-content {
        background-color: #ffffcc;
    }
    .el-checkbox__inner {
        background-color: transparent;
    }
    .error .el-checkbox__inner {
        border: 1px solid red;
    }
    .modal-footer {
        .el-checkbox__label {
            font-size: 1.2rem;
        }
        .el-checkbox__inner {
            width: 1.2rem;
            height: 1.2rem;
        }
    }
}

.custom-toggler {
    border: none;
    font-size: 1rem;
    &:focus, &:active, .navbar-toggler-icon:focus {
        outline: none;
        box-shadow: none;
    }
    .navbar-toggler-icon {

    }
}

.vendor-table {
    .el-table__column-filter-trigger {
        position: absolute;
        right: 5px;
        bottom: 5px;
        line-height: 26px;
        font-size: 16px;

        .filtered {
          color: $second-primary;
        }
    }
    th {
        position: relative;
    }
}
