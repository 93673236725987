$bg-color-blue: #e0f4ff;
$bg-color-blue-hover: #c9dee9;

$bg-color-green: #e2fff3;
$bg-color-green-hover: #abc8bc;

$bg-color-red: #fbf1f1;
$bg-color-red-hover: #d8cece;

$bg-color-grey: #f8f8f8;
$bg-color-grey-hover: #dadada;

$bg-color-white: #ffffff;
$bg-color-white-hover: #f2f2f2;

$bg-color-orange: #ffe8d4;
$bg-color-orange-hover: #cab4a1;

$bg-color-yellow: #fbf9ec;
$bg-color-yellow-hover: #c7c5b8;

.status-bg-blue {
	background-color: $bg-color-blue;
}

.status-bg-green {
	background-color: $bg-color-green;
}

.status-bg-red {
	background-color: $bg-color-red;
}

.status-bg-grey {
	background-color: $bg-color-grey;
}

.status-bg-white {
	background-color: $bg-color-white;
}

.status-bg-orange {
	background-color: $bg-color-orange;
}

.status-bg-yellow {
	background-color: $bg-color-yellow;
}

th.common-item {
	background-color: #d1d1d1 !important;
}
td.common-item {
	background-color: #f9f9f9 !important;
}
th.csr-item {
	background-color: #b5cbe7 !important;
}
td.csr-item {
	background-color: #e9f3ff !important;
}
td.csr-item.scap-warning-1, td.csr-item.evidence-warning-1 {
	background-color: #ffff9a !important;
}
td.csr-item.scap-warning-2, td.csr-item.evidence-warning-2 {
	background-color: #ff8383 !important;
}
th.mfg-process-item {
	background-color: #a6e3a6 !important;
}
td.mfg-process-item {
	background-color: #ebffec !important;
}
th.factory-examination-item {
	background-color: #ebaf7e !important;
}
td.factory-examination-item {
	background-color: #ffebdb !important;
}

table {
	tr.status-bg-blue {
		&:hover {
			td, td.freeze {
				background-color: $bg-color-blue-hover;
			}
		}
		td, td.freeze {
			background-color: $bg-color-blue;
		}
	}
	tr.status-bg-green {
		&:hover {
			td, td.freeze {
				background-color: $bg-color-green-hover;
			}
		}
		td, td.freeze {
			background-color: $bg-color-green;
		}
	}
	tr.status-bg-red {
		&:hover {
			td, td.freeze {
				background-color: $bg-color-red-hover;
			}
		}
		td, td.freeze {
			background-color: $bg-color-red;
		}
	}
	tr.status-bg-yellow {
		&:hover {
			td, td.freeze {
				background-color: $bg-color-yellow-hover;
			}
		}
		td, td.freeze {
			background-color: $bg-color-yellow;
		}
	}
	tr.status-bg-orange {
		&:hover {
			td, td.freeze {
				background-color: $bg-color-orange-hover;
			}
		}
		td, td.freeze {
			background-color: $bg-color-orange;
		}
	}
	tr.status-bg-grey {
		&:hover {
			td, td.freeze {
				background-color: $bg-color-grey-hover;
			}
		}
		td, td.freeze {
			background-color: $bg-color-grey;
		}
	}
	tr.status-bg-white {
		&:hover {
			td, td.freeze {
				background-color: $bg-color-white-hover;
			}
		}
		td, td.freeze {
			background-color: $bg-color-white;
		}

	}
}
