.user-container {
    .user-image {
        height: 180px;
        max-width: 360px;
    }
}

.user-list {
    th {
        min-width: 105px;
        position: relative;
    }
    .pagination-right {
        display: flex;
        justify-content: flex-end;
    }
    .el-table__column-filter-trigger {
      position: absolute;
      right: 5px;
      bottom: 5px;
      line-height: 26px;
      font-size: 16px;
      .filtered {
          color: $second-primary;
      }
    }
}
